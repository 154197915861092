@use "global" as *;

@font-face {
  font-family: "icomoon";
  src: url("/_themes/common/fonts/icomoon.eot?ujwzu1");
  src: url("/_themes/common/fonts/icomoon.eot?ujwzu1#iefix") format("embedded-opentype"),
    url("/_themes/common/fonts/icomoon.ttf?ujwzu1") format("truetype"),
    url("/_themes/common/fonts/icomoon.woff?ujwzu1") format("woff"),
    url("/_themes/common/fonts/icomoon.svg?ujwzu1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home3:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e942";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-ctrl:before {
  content: "\ea50";
}
.icon-mail2:before {
  content: "\ea84";
}
