@use "global" as *;

.p-mainVisual {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.p-mainVisual.-margin-on {
  padding: 8px;
}

.p-mainVisual.-height-full {
  height: calc(var(--swl-mv-height, 100vh) - var(--swl-headerH, 0px) - var(--swl-adminbarH, 0px));
}

.p-mainVisual.-height-full .p-mainVisual__inner {
  height: 100%;
}

.-height-img [src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="] {
  height: 0;
}

.-height-img .swiper-slide {
  width: var(--swl-mv_slide_width--pc, 100%);
}

.-height-img .p-mainVisual__imgLayer {
  position: relative;
  height: auto;
}

.-height-img .p-mainVisual__img {
  display: block;
  width: 100%;
  height: auto;
}

.p-mainVisual__inner {
  position: relative;
}

.-height-auto .p-mainVisual__inner {
  height: auto;
}

.p-mainVisual__slide {
  position: relative;
  height: auto;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.-height-full .p-mainVisual__slide,
.-height-set .p-mainVisual__slide {
  height: 100%;
}

.p-mainVisual__slideLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-mainVisual__textLayer {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.p-mainVisual__textLayer > .p-blogParts {
  margin: 1em 0 0;
  text-align: left;
}

.-height-auto .p-mainVisual__textLayer {
  position: relative;
  left: 0;
  padding-top: 4em;
  padding-bottom: 4em;
  -webkit-transform: none;
  transform: none;
}

.p-mainVisual__slideTitle {
  font-size: 5.5vw;
  line-height: 1.4;
}

.p-mainVisual__slideText {
  margin-top: 0.75em;
  font-size: 3.2vw;
}

.-height-full .p-mainVisual__slideText {
  font-size: 3.4vw;
}

.p-mainVisual__scroll {
  position: absolute;
  bottom: 16px;
  left: 50%;
  z-index: 9;
  color: #fff;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.75;
  transition: opacity 0.25s;
}

.-height-full > .p-mainVisual__scroll {
  bottom: 24px;
}

.p-mainVisual__scroll:hover {
  opacity: 1;
}

.p-mainVisual__scrollArrow {
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  -webkit-animation: FloatVertical 3s ease-in-out infinite alternate;
  animation: FloatVertical 3s ease-in-out infinite alternate;
}

.p-mainVisual__scrollLabel {
  display: block;
  padding-top: 4px;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1px;
}

.p-mainVisual {
  --swiper-nav-size: 40px;
}

.p-mainVisual .swiper:not(.swiper-initialized) {
  visibility: hidden;
}

.p-mainVisual .swiper-wrapper {
  align-content: stretch;
}

.p-mainVisual .swiper-button-prev {
  left: 0;
}

.p-mainVisual .swiper-button-next {
  right: 0;
}

.p-mainVisual .swiper-pagination-bullet-active {
  background: #fff;
}

.p-mainVisual .swiper-button-next svg,
.p-mainVisual .swiper-button-prev svg {
  display: none;
}

.p-mainVisual:not(.-motion-no) .swiper-slide-active .p-mainVisual__imgLayer,
.p-mainVisual:not(.-motion-no) .swiper-slide-duplicate-active .p-mainVisual__imgLayer,
.p-mainVisual:not(.-motion-no) .swiper-slide-prev .p-mainVisual__imgLayer {
  -webkit-animation: var(--mv_slide_animation) 10s linear 0s 1 normal both;
  animation: var(--mv_slide_animation) 10s linear 0s 1 normal both;
}

.-motion-leftToRight .p-mainVisual__imgLayer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

@media (min-width: 600px) {
  .p-mainVisual.-margin-on {
    padding: 16px;
  }

  .p-mainVisual__slideTitle {
    font-size: 28px;
  }

  .p-mainVisual__slideText {
    font-size: 16px;
  }

  .-height-full .p-mainVisual__slideText {
    font-size: 18px;
  }
}

@media (min-width: 960px) {
  .-motion-leftToRight .p-mainVisual__imgLayer {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@media (max-width: 959px) {
  .-height-img .swiper-slide {
    width: var(--swl-mv_slide_width--sp, 100%);
  }

  .p-mainVisual__textLayer {
    padding: 0 4vw;
  }
}

@media (max-width: 599px) {
  .p-mainVisual {
    --swiper-nav-size: 32px;
    --swiper-nav-border-width: 1px;
  }
}
