@use "global" as *;

.w-beforeFooter {
  margin: 6em auto 3em;
  overflow: hidden;
}
.c-widget__title.-footer::before {
  background-color: currentColor !important;
}
.l-footer {
  position: relative;
  z-index: 0;
  color: #ffffff;
  background-color: #ff8c9b;
  width: 100%;
}

.l-footer__inner {
  padding-top: 10px; /*--  20230824 add --*/
  margin: 0 auto;
  max-width: 1420px; /*--  20230824 add --*/
  display: flex;
  justify-content: space-between;
  @media (max-width: 959px) {
    display: block;
  }
}

.footer-logo {
  @media (max-width: 959px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.footer-logo > img {
  /*--↓↓↓ width: 391px;-- 230731 */
  width: 320px;
  /*--↓↓↓ height: 96px;-- 230731 */
  height: auto;
  /*--↓↓↓ NEW -- 230731 */
  margin: 0 0 20px 0;
}

.footer-logo > p {
  /*--  padding-left: 0.5em;  20230824--*/
  /*--↓↓↓       font-size: 1.294rem;-- 230731 */
  font-size: 1.2rem;
  @media (max-width: 959px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.footer-corpInfo {
  /*--  margin-top: 33px;  20230824--*/
  margin-bottom: 71px;
  @media (max-width: 959px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.footer-corpInfo ul {
  /*--↓↓↓        NEW-- 230731 */
  padding: 0;
}

.footer-corpInfo > ul > li {
  /*--↓↓↓        margin-bottom: 17px;-- 230731 */
  margin-bottom: 0;
  /*--↓↓↓       font-size: 1.294rem;-- 230731 */
  font-size: 1.2rem;
}

.l-footer a,
.l-footer span {
  color: inherit;
}
.l-footer .copyright {
  /*--↓↓↓       font-size: 1rem;-- 230731 */
  font-size: 1rem; /*--  20230824 add --*/
  text-align: center;
  /*--↓↓↓        padding-bottom: 43px;-- 230731 */
  padding-bottom: 10px;
}
.l-footer .copyright a {
  color: var(--color_link);
}
.l-footer__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5em 0 1em;
  color: inherit;
}
.l-footer__nav a {
  display: block;
  padding: 0.25em 0.5em;
  color: inherit;
  font-size: 3vw;
  text-decoration: none;
}
.l-footer__nav a:hover {
  text-decoration: underline;
}

.l-footer__foot > .l-container {
  padding: 0;
  @media (max-width: 959px) {
    display: none;
  }
}

.c-iconList > li {
  /*--↓↓↓     margin-left: 0.25em;-- 230731 */
  margin-left: 0.8em;
  /*--↓↓↓   font-size: 1.412em;-- 230731 */
  font-size: 1em; /*--  20230824 add --*/
}

.c-iconList > li > a {
  text-decoration: none;
}

.l-footer__widgetArea {
  padding-top: 1em;
  padding-bottom: 1em;
}
.p-indexModal {
  padding: 80px 16px;
}
.p-indexModal__inner {
  position: relative;
  z-index: 1;
  max-width: 800px;
  height: 100%;
  margin: -1em auto 0 !important;
  padding: 2em 1.5em;
  color: #333;
  background: #fdfdfd;
  border-radius: var(--swl-radius--4, 0);
  -webkit-transform: translateY(16px);
  transform: translateY(16px);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.is-opened > .p-indexModal__inner {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.p-indexModal__close {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 0.5em;
  color: #fff;
  line-height: 2;
  text-align: center;
  text-shadow: 1px 1px 1px #000;
  cursor: pointer;
}
.p-searchModal__inner {
  position: absolute;
  top: calc(50% + 16px);
  left: 50%;
  z-index: 1;
  width: 96%;
  max-width: 600px;
  padding: 1em;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transition: top 0.5s;
}
.is-opened > .p-searchModal__inner {
  top: 50%;
}
.p-searchModal__inner .c-searchForm {
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.2);
}
.p-searchModal__inner .s {
  font-size: 16px;
}
@media (min-width: 600px) {
  .l-footer__nav a {
    padding: 0 0.75em;
    font-size: 13px;
    border-right: 1px solid;
  }
  .l-footer__nav li:first-child a {
    border-left: 1px solid;
  }
  .p-indexModal__inner {
    padding: 2em;
  }
}
@media (min-width: 960px) {
  .w-footer {
    display: flex;
    justify-content: space-between;
  }
  .w-footer__box {
    flex: 1;
    padding: 1em;
  }
}
@media (max-width: 959px) {
  .w-footer__box {
    padding: 1em 0;
  }
  .w-footer__box + .w-footer__box {
    margin-top: 1em;
  }
  .l-footer__inner {
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);
  }
}
