@use "global" as *;
// トップビジュアル(About)
.kotei-about-topvisual {
  position: relative;

  @media (min-width: 600px) {
    min-height: 240px; /*--20230825--*/
    padding: 1em 0;
  }

  @media (max-width: 599px) {
    width: auto;
    min-height: 280px;
    height: auto;
    padding: 1em 0;

    img {
      width: 100%;
      min-height: 280px; // 画像の最小高さを指定
      height: auto;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; // 画像を中央に配置

    @media (min-width: 600px) {
      object-position: center;
    }
  }

  .l-topTitleArea__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
    color: #000000;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 959px) {
      top: 50%;
      text-align: center;
    }

    .c-pageTitle {
      /*--  position: relative;
  font-size: 1.4rem;
  line-height: 1.4; 20230825--*/
      font-size: 1.924rem;
      /*--     width: 60%; 20230825--*/
      margin: 0 auto;
      color: #fff;
      text-shadow: 1px 1px 2px #666; /*-- 20230825--*/
      font-weight: bold; /*-- 20230825--*/

      @media (min-width: 600px) {
        font-size: 1.5rem; /*--20230825--*/
      }

      @media (max-width: 599px) {
        text-align: center;
      }
    }
  }
}

// パンくずリスト
#breadCrumbs {
  margin-left: var(--swl-h2-margin--x, 0);
  ol {
    padding: 0;
    margin: 0; /*--20230902--*/
    line-height: 0.8; /*--20230902--*/
  }
  li {
    display: inline-block;
    font-size: 0.8rem; /*--20230829--*/
    margin: 0; /*--20230829--*/

    &::after {
      content: ">";
      padding-left: 10px;
      padding-right: 10px;
    }
    &:last-child::after {
      display: none;
    }
    a {
      text-decoration: none;
      color: #000;
      &:hover {
        color: #ad0e15;
        transition: 0.3s;
      }
    }
  }
}

//見出し設定
.kaso {
  .post_content {
    h1 {
      margin-right: var(--swl-h2-margin--x, 0);
      margin-left: var(--swl-h2-margin--x, 0);
      margin-bottom: 1.5em;
      padding: 1em;
      border-radius: 2px;
      color: #fff;
      background: var(--color_htag);
      font-weight: 700;
      position: relative;
      z-index: 1;
      font-size: 1.2em;
      line-height: 1.4;
      @media (min-width: 600px) {
        font-size: 1.647em;
      }
      &::before {
        content: "";
        display: inline-block;
        width: 1.55em;
        height: 1.55em;
        background-image: url("/_themes/common/images/h2Titleimg.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: middle;
        margin-right: 5px;
      }
      &::after {
        position: absolute;
        display: block;
        pointer-events: none;
        content: "";
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        top: 4px;
        left: 4px;
        right: auto;
        bottom: auto;
        border: dashed 1px #fff;
      }
    }
    h2 {
      /*--  margin-right: var(--swl-h2-margin--x, 0);
  margin-left: var(--swl-h2-margin--x, 0);　　--*/ /*--20230903---*/
      margin-bottom: 1.5em;
      &::before {
        border: none;
      }
    }
  }
}

// 会社概要[[content]]内
.contentBody {
  font-size: 1.059rem;
  .contentBody__h1-inner {
    margin-bottom: 3em;
  }
  // 挨拶
  .motto {
    .conts {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
      text-align: center;
      font-size: 2.235rem;
      @media (max-width: 599px) {
        font-size: 1.5rem;
      }
    }
    .comment {
      color: #707070;
    }
  }

  // 営業範囲
  .businessScope {
    display: flex;
    @media (max-width: 959px) {
      display: block;
    }
    &__img {
      margin-right: 78.8px;
      height: 422.57px;
      @media (max-width: 959px) {
        margin: 0 auto;
        height: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &__area {
      width: fit-content;
      .businessScope__area {
        padding-left: 15px;
        &__title {
          padding: 0;
          font-weight: normal;
          @media (max-width: 599px) {
            font-size: 1.471rem;
          }
        }
        &__name {
          color: #707070;
          font-size: 1.353rem;
        }
      }
      .businessScope__area + .businessScope__area {
        margin-top: 1.176rem;
      }
      &__consultation {
        margin-top: 2.824rem;
        padding: 0.588em 0 1.765rem 0;
        border: 3px solid #ffb6c1;
        &__title {
          margin: 0;
          margin-left: 1.824rem;
          font-weight: normal;
          &::before {
            content: none;
          }
        }
        &__annotation {
          margin-top: 0.882rem;
          margin-left: 1.824rem;
          color: #ff0000;
          font-size: 0.882rem;
        }
        &__area {
          margin-top: 1.294rem;
          margin-left: 2.412rem;
          color: #707070;
        }
      }
    }
  }

  // googleマップ
  .googleMap {
    min-width: 100%;
    height: 408px;
  }
  .contentBody__h2-inner {
    p {
      color: #707070;
    }
  }
}

// 会社情報メニュー
.corp-info-menu--title {
  margin-bottom: 1.5rem !important;
  @media (max-width: 599px) {
    margin-bottom: 0.8rem !important;
  }
}
.corp-info-menu {
  margin-right: var(--swl-h2-margin--x, 0);
  margin-left: var(--swl-h2-margin--x, 0);
  margin-bottom: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 6px;
  @media (max-width: 599px) {
    margin-bottom: 4rem;
  }

  &__item {
    flex: 0 1 calc(50% - 3px);
    /*-- height: 115px;  20230824 add --*/
    /*--  padding: 20px 0;  20230824 add --*/
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #fff1f3;
    @media (max-width: 599px) {
      height: 80px;
    }

    a {
      padding: 20px 0; /*-- 20230824 add --*/
      color: #000000;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding-left: 47px;
      font-size: 1.4rem; /*-- 20230824 add --*/
      width: 100%;
      height: 100%;
      background-color: #fff1f3; /*-- 20230824 add --*/
      @media (max-width: 599px) {
        font-size: 1rem;
        padding-left: 25px;
      }

      &::before {
        content: "\025b6";
        color: #ff6179;
        margin-right: 0.824rem;
        font-size: 51%; /*--  20230824 add --*/
      }
      &:hover {
        background-color: #f5dde1; /*-- 20230824 add --*/
      }
    }
  }
}

// スタッフ紹介
#stafflist.piece {
  .pieceContainer {
    .pieceBody {
      ul {
        padding-left: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        flex-direction: column;
        gap: 10px; /*-- 20230825--*/
        @media (max-width: 599px) {
          display: block;
        }
        .staff.current {
          display: none;
        }
      }
      .content {
        li {
          list-style: none;
          text-align: left;
          display: flex;
          color: #707070;
          @media (max-width: 599px) {
            display: block;
          }

          justify-content: center;
          align-items: center;
          .image {
            display: block;
            width: 200%;
            height: auto;
            @media (max-width: 599px) {
              width: 100%;
            }
            img {
              width: 100%;
            }
            @media (max-width: 959px) {
              margin: 0 auto;
            }
            @media (max-width: 599px) {
              max-height: none;
            }
          }
          .title_link {
            a {
              display: block;
              padding-top: 1rem;
              text-align: center;
              line-height: 1;
              font-size: 1rem; /*-- 20230825--*/
              color: #707070;
              font-weight: normal;
              @media (max-width: 599px) {
                font-size: 1.2rem;
              }
            }
          }
          .body {
            display: block;
            padding-top: 10px;
            line-height: 1.8;
          }
          .text-content-wrapper {
            padding-left: 50px;
            @media (max-width: 599px) {
              padding: 0;
            }
            .title_link {
              a {
                text-align: left;
                border-bottom: #ffb6c1 2px solid;
                color: #707070;
                font-size: 1.5rem;
                padding-bottom: 10px;
              }
            }
          }
          .doc_link {
            display: block;
            margin-top: 1rem;
            color: #707070;
            text-align: right;
          }
        }
        li + li {
          border-top: 1px dashed #707070;
          padding-top: 40px;
          @media (max-width: 599px) {
            padding-top: 50px;
            margin-top: 50px;
          }
        }
      }
    }
  }
}

// お問い合わせフォーム
.surveyForm {
  form {
    .questions {
      .question {
        .free {
          label {
            display: none;
          }
        }
        .question_content {
          label {
            font-weight: bold;
            font-size: 1.2rem;
            color: #000000;
            .note {
              color: #ff0000;
            }
          }
        }
      }
    }

    .question {
      .answer_content {
        input,
        textarea {
          border-radius: 4px;
        }
        input[type="text"],
        input[type="email"] {
          width: 100%;
        }
        .max_length {
          display: none;
        }
        .checkbox-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          label {
            margin-left: 5px;
          }
          input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin: 0;
            padding: 0;
            background: none;
            border: 1px solid #707070;
            appearance: checkbox;
          }
        }
        textarea {
          width: 100%;
        }

        .captchaForm {
          .reload,
          .talk {
            margin: 10px 0;
            display: inline-block;
            width: fit-content;
            border: 1px solid #e9e9e9;
            padding: 2.5px;
            background-color: #f7f7f7;
            a {
              display: block;
              color: #5d5a69;
            }
          }
        }
      }
    }

    .question + .question {
      margin-top: 15px;
    }
  }
}

// カテゴリー一覧
.contentGpCategoryCategoryType,
.contentGpCategoryCategoryTypes {
  section + section {
    margin-top: 55px;
  }
  section {
    h2 {
      margin-bottom: 1rem;
      a {
        display: block;
        color: #fff;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 1.55em;
          height: 1.55em;
          background-image: url("/_themes/common/images/h2Titleimg.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
}

.contentGpCategoryCategoryType {
  .categoryTypeFaq {
    .docs {
      ul {
        padding-left: 0;
      }
      li {
        list-style: none;
        .title_link {
          a {
            /*--  color: #0022ff; --*/ /*-- 20230825--*/
            text-decoration: underline;
          }
        }
        .body {
          display: none;
        }
        .publish_date {
          display: none;
        }
        .doc_link {
          display: none;
        }
      }
    }
  }
}

.contentGpCategory.contentGpCategoryCategory,
.contentGpCategoryCategoryType {
  .categoryVoice,
  .categoryNews,
  .categoryStaff,
  .categoryTypeNews,
  .categoryTypeBlog,
  .categoryTypeStafflist {
    position: relative;
    .docs {
      ul {
        padding-left: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        flex-direction: column;
        gap: 58px;
        @media (max-width: 599px) {
          display: block;
        }
        .staff.current {
          display: none;
        }
      }
      li {
        color: #707070;
        list-style: none;
        text-align: left;
        display: flex;
        @media (max-width: 599px) {
          display: block;
        }

        justify-content: center;
        align-items: center;
        .image {
          display: block;
          /*--↓↓↓       width: 200%;-- 230731 */
          width: 100%;
          height: auto;
          @media (max-width: 599px) {
            width: 100%;
          }
          img {
            width: 100%;
          }
          @media (max-width: 959px) {
            margin: 0 auto;
          }
          @media (max-width: 599px) {
            max-height: none;
          }
        }
        .title_link {
          a {
            display: block;
            padding-top: 1rem;
            text-align: center;
            line-height: 1;
            font-size: 1.059rem;
            color: #707070;
            font-weight: normal;
            @media (max-width: 599px) {
              font-size: 1.2rem;
            }
          }
        }
        .body {
          padding-top: 10px;
          line-height: 1.8;
        }
        .text-content-wrapper {
          padding-left: 20px; /*--  20230824 add --*/
          @media (max-width: 599px) {
            padding: 0;
          }
          .title_link {
            a {
              text-align: left;
              border-bottom: #ffb6c1 2px solid;
              color: #707070;
              font-size: 1.5rem;
              padding-bottom: 10px;
            }
          }
        }
        .doc_link {
          display: block;
          margin-top: 1rem;
          color: #707070;
          text-align: right;
        }
      }
      li + li {
        border-top: 1px dashed #707070;
        padding-top: 40px;
        @media (max-width: 599px) {
          padding-top: 50px;
          margin-top: 50px;
        }
      }
    }
  }
}

/*------------------------------------------------------
feed
------------------------------------------------------*/

.feed {
  li {
    float: left;
    width: 49px !important;
    height: 20px;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    .feedRss {
      background-image: url("/_themes/common/images/ic-rss.png");
      background-position: left top;
      background-repeat: no-repeat;
      display: block;
      float: left;
      height: 20px;
      margin: 0 5px 0 0;
      text-indent: -9999px;
      width: 49px;
    }
    .feedAtom {
      background-image: url("/_themes/common/images/ic-atom.png");
      background-position: right top;
      background-repeat: no-repeat;
      display: block;
      float: left;
      height: 20px;
      text-indent: -9999px;
      width: 49px;
    }
  }
}

.contentGpCategoryCategoryType ul.feed,
.contentGpCategory ul.feed,
.categoryType ul.feed,
.contentGpArticleDocs ul.feed {
  margin: 0 0 30px 0;
  overflow: hidden;

  /* padding: 0 0 0 1087px; */
  position: absolute;
  right: 22px;
}

.contentGpCategoryCategoryType ul.feed {
  top: -30px;
}

.contentGpCategory ul.feed {
  top: -95px; /*--  20230824 add --*/
  z-index: 1000; /*--  20230824 add --*/

  li {
    display: inline !important;
  }
}

/*------------------------------------------------------
 more
------------------------------------------------------*/

.more {
  text-align: right;
  margin: 10px 0 0 0;

  span {
    padding-right: 10px;
  }
}

#whatsnew .more a {
  border: none;
  text-align: left;
  width: 191px;
  padding: 0 20px 0 17px;
  position: relative;
}

/* contentGpArticleDoc
----------------------------------------------- */
.contentGpArticleDoc .date {
  margin-bottom: 1em;
  text-align: right;
}
.content .contentGpArticleDoc .date p {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.contentGpArticleDoc .date p.publishedAt {
  padding-right: 0;
}
