@use "global" as *;
:root {
  --color_main: #ffb6c1;
  --color_text: #333;
  --color_link: #ff919f;
  --color_border: rgba(200, 200, 200, 0.5);
  --color_gray: rgba(200, 200, 200, 0.15);
  --color_htag: #ffb6c1;
  --color_bg: #ffffff;
  --color_gradient1: #d8ffff;
  --color_gradient2: #87e7ff;
  --color_main_thin: rgba(255, 228, 241, 0.05);
  --color_main_dark: rgba(191, 137, 145, 1);
  --color_list_check: #ffb6c1;
  --color_list_num: #ffb6c1;
  --color_list_good: #86dd7b;
  --color_list_bad: #f36060;
  --color_faq_q: #d55656;
  --color_faq_a: #6599b7;
  --color_capbox_01: #f59b5f;
  --color_capbox_01_bg: #fff8eb;
  --color_capbox_02: #5fb9f5;
  --color_capbox_02_bg: #edf5ff;
  --color_capbox_03: #2fcd90;
  --color_capbox_03_bg: #eafaf2;
  --color_icon_good: #3cd250;
  --color_icon_good_bg: #ecffe9;
  --color_icon_bad: #4b73eb;
  --color_icon_bad_bg: #eafaff;
  --color_icon_info: #f578b4;
  --color_icon_info_bg: #fff0fa;
  --color_icon_announce: #ffa537;
  --color_icon_announce_bg: #fff5f0;
  --color_icon_pen: #7a7a7a;
  --color_icon_pen_bg: #f7f7f7;
  --color_icon_book: #787364;
  --color_icon_book_bg: #f8f6ef;
  --color_icon_point: #ffa639;
  --color_icon_check: #86d67c;
  --color_icon_batsu: #f36060;
  --color_icon_hatena: #5295cc;
  --color_icon_caution: #f7da38;
  --color_icon_memo: #84878a;
  --color_btn_red: #f74a4a;
  --color_btn_blue: #ffc44f;
  --color_btn_green: #80d86c;
  --color_btn_blue_dark: rgba(191, 147, 59, 1);
  --color_btn_red_dark: rgba(185, 56, 56, 1);
  --color_btn_green_dark: rgba(96, 162, 81, 1);
  --color_deep01: #e44141;
  --color_deep02: #3d79d5;
  --color_deep03: #63a84d;
  --color_deep04: #f09f4d;
  --color_pale01: #fff2f0;
  --color_pale02: #f3f8fd;
  --color_pale03: #f1f9ee;
  --color_pale04: #fdf9ee;
  --color_mark_blue: #b7e3ff;
  --color_mark_green: #bdf9c3;
  --color_mark_yellow: #fcf69f;
  --color_mark_orange: #ffddbc;
  --border01: solid 1px var(--color_main);
  --border02: double 4px var(--color_main);
  --border03: dashed 2px var(--color_border);
  --border04: solid 4px var(--color_gray);
  --card_posts_thumb_ratio: 56.25%;
  --list_posts_thumb_ratio: 61.8%;
  --big_posts_thumb_ratio: 56.25%;
  --thumb_posts_thumb_ratio: 61.8%;
  --swl-content_font_size: 4vw;
  --color_header_bg: #fdfdfd;
  --color_header_text: #333;
  --color_footer_bg: #fdfdfd;
  --color_footer_text: #ffb6c1;
  --container_size: 1491px;
  --article_size: 900px;
  --logo_size_sp: 48px;
  --logo_size_pc: 50px;
  --logo_size_pcfix: 48px;
}

.is-style-btn_normal a {
  border-radius: 80px;
}

.is-style-btn_solid a {
  border-radius: 80px;
}

.is-style-btn_shiny a {
  border-radius: 80px;
}

.is-style-btn_line a {
  border-radius: 80px;
}

.post_content blockquote {
  padding: 1.5em 2em 1.5em 3em;
}

.post_content blockquote::before {
  content: "";
  display: block;
  width: 5px;
  height: calc(100% - 3em);
  top: 1.5em;
  left: 1.5em;
  border-left: solid 1px rgba(180, 180, 180, 0.75);
  border-right: solid 1px rgba(180, 180, 180, 0.75);
}

.mark_blue {
  background: -webkit-linear-gradient(transparent 64%, var(--color_mark_blue) 0%);
  background: linear-gradient(transparent 64%, var(--color_mark_blue) 0%);
}

.mark_green {
  background: -webkit-linear-gradient(transparent 64%, var(--color_mark_green) 0%);
  background: linear-gradient(transparent 64%, var(--color_mark_green) 0%);
}

.mark_yellow {
  background: -webkit-linear-gradient(transparent 64%, var(--color_mark_yellow) 0%);
  background: linear-gradient(transparent 64%, var(--color_mark_yellow) 0%);
}

.mark_orange {
  background: -webkit-linear-gradient(transparent 64%, var(--color_mark_orange) 0%);
  background: linear-gradient(transparent 64%, var(--color_mark_orange) 0%);
}

[class*="is-style-icon_"] {
  color: #333;
  border-width: 0;
}

[class*="is-style-big_icon_"] {
  position: relative;
  padding: 2em 1.5em 1.5em;
  border-width: 2px;
  border-style: solid;
}

[data-col="gray"] .c-balloon__text {
  background: #f7f7f7;
  border-color: #ccc;
}

[data-col="gray"] .c-balloon__before {
  border-right-color: #f7f7f7;
}

[data-col="green"] .c-balloon__text {
  background: #d1f8c2;
  border-color: #9ddd93;
}

[data-col="green"] .c-balloon__before {
  border-right-color: #d1f8c2;
}

[data-col="blue"] .c-balloon__text {
  background: #e2f6ff;
  border-color: #93d2f0;
}

[data-col="blue"] .c-balloon__before {
  border-right-color: #e2f6ff;
}

[data-col="red"] .c-balloon__text {
  background: #ffebeb;
  border-color: #f48789;
}

[data-col="red"] .c-balloon__before {
  border-right-color: #ffebeb;
}

[data-col="yellow"] .c-balloon__text {
  background: #f9f7d2;
  border-color: #fbe593;
}

[data-col="yellow"] .c-balloon__before {
  border-right-color: #f9f7d2;
}

.-type-list2 .p-postList__body::after,
.-type-big .p-postList__body::after {
  content: "READ MORE »";
}

.c-postThumb__cat {
  background-color: #ffb6c1;
  color: #fff;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1) 6px,
    transparent 6px,
    transparent 12px
  );
}

.post_content h2 {
  padding: 1em;
  border-radius: 2px;
  color: #fff;
  background: var(--color_htag);
}

.post_content h2::before {
  position: absolute;
  display: block;
  pointer-events: none;
  content: "";
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  top: 4px;
  left: 4px;
  right: auto;
  bottom: auto;
  border: dashed 1px #fff;
}

.post_content h3 {
  padding: 0 0.5em 0.5em;
}

.post_content h3::before {
  content: "";
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(
    90deg,
    var(--color_htag) 0%,
    var(--color_htag) 29.3%,
    rgba(255, 228, 241, 0.2) 29.3%,
    rgba(255, 228, 241, 0.2) 100%
  );
}

.post_content h4::before {
  content: "\e923";
  display: inline-block;
  font-family: "icomoon";
  margin-right: 0.5em;
  color: var(--color_htag);
}

body {
  font-weight: 400;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo,
    sans-serif;
}

.l-header {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.l-header__bar {
  color: #707070;
  background: #ffffff;
}

.l-header__customBtn {
  color: #fff;
  background-color: #ff8c8c;
}

.c-gnav a::after {
  background: var(--color_main);
  width: 100%;
  height: 0px;
}

.p-spHeadMenu .menu-item.-current {
  border-bottom-color: var(--color_main);
}

.c-gnav > li:hover > a::after,
.c-gnav > .-current > a::after {
  height: 6px;
}

.c-gnav .sub-menu {
  color: #333;
  background: #fff;
}

.l-fixHeader::before {
  opacity: 1;
}

.c-infoBar {
  color: #fff;
  background-color: #ff8c9b;
}

.c-infoBar__text {
  font-size: 3.8vw;
}

.c-infoBar__btn {
  background-color: var(--color_main) !important;
}

#pagetop {
  border-radius: 50%;
}

#fix_bottom_menu {
  color: #ffffff;
}

#fix_bottom_menu::before {
  background: #ff8c9b;
  opacity: 0.9;
}

#before_footer_widget {
  margin-bottom: 0;
}

.c-widget__title.-spmenu {
  border-left: solid 2px var(--color_main);
  padding: 0em 0.75em;
}

.c-widget__title.-footer {
  padding: 0.5em;
}

.c-widget__title.-footer::before {
  content: "";
  bottom: 0;
  left: 0;
  width: 40%;
  z-index: 1;
  background: var(--color_main);
}

.c-widget__title.-footer::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--color_border);
}

.c-secTitle {
  border-left: solid 2px var(--color_main);
  padding: 0em 0.75em;
}

.p-spMenu {
  color: #ff8c9b;
}

.p-spMenu__inner::before {
  background: #fdfdfd;
  opacity: 1;
}

.p-spMenu__overlay {
  // background: #000;
  // opacity: 0.6;
}

[class*="page-numbers"] {
  border-radius: 50%;
  margin: 4px;
  color: var(--color_main);
  border: solid 1px var(--color_main);
}

.l-topTitleArea.c-filterLayer::before {
  background-color: #000000;
  opacity: 0.2;
  content: "";
}

@media screen and (min-width: 960px) {
  :root {
  }
}

@media screen and (max-width: 959px) {
  :root {
  }

  .l-header__logo {
    margin-right: auto;
  }

  .l-header__inner {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  #pagetop {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  :root {
    --swl-content_font_size: 17px;
  }

  .c-infoBar__text {
    font-size: 16px;
  }
}

@media screen and (max-width: 599px) {
  :root {
  }
}

@media (min-width: 1108px) {
  .alignwide {
    left: -100px;
    width: calc(100% + 200px);
  }
}

@media (max-width: 1108px) {
  .-sidebar-off .swell-block-fullWide__inner.l-container .alignwide {
    left: 0px;
    width: 100%;
  }
}

.l-fixHeader .l-fixHeader__gnav {
  order: 0;
}

[data-scrolled="true"] .l-fixHeader[data-ready] {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
  visibility: visible;
  opacity: 1;
}

.-body-solid .l-fixHeader {
  box-shadow: 0 2px 4px var(--swl-color_shadow);
}

.l-fixHeader__inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: stretch;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--color_header_text);
}

.l-fixHeader__logo {
  display: flex;
  align-items: center;
  order: 0;
  margin-right: 24px;
  padding: 16px 0;
  line-height: 1;
}

.c-infoBar {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.c-infoBar.-bg-stripe::before {
  display: block;
  background-image: repeating-linear-gradient(-45deg, #fff 0 6px, transparent 6px 12px);
  opacity: 0.1;
  content: "";
}

.c-infoBar__link {
  position: relative;
  z-index: 0;
  display: block;
  color: inherit;
  text-decoration: none;
}

.c-infoBar__text {
  position: relative;
  z-index: 1;
  display: block;
  padding: 8px 0;
  color: inherit;
  font-weight: 700;
}

.c-infoBar__text.-flow-on {
  white-space: nowrap;
  text-align: left;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-animation: flowing_text 12s linear infinite;
  animation: flowing_text 12s linear infinite;
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
}

.c-infoBar__text.-flow-off {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.c-infoBar__btn {
  display: block;
  width: auto;
  min-width: 1em;
  margin-left: 1em;
  padding: 0 1em;
  color: #fff;
  font-size: 0.95em;
  line-height: 2;
  text-decoration: none;
  border-radius: 40px;
  box-shadow: 0 2px 4px var(--swl-color_shadow);
}

@media (min-width: 960px) {
  .c-infoBar__text.-flow-on {
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
  }
}

@media (min-width: 1200px) {
  .c-infoBar__text.-flow-on {
    -webkit-animation-duration: 24s;
    animation-duration: 24s;
  }
}

@media (min-width: 1600px) {
  .c-infoBar__text.-flow-on {
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
  }
}

[data-scrolled="true"] #fix_bottom_menu {
  bottom: 0;
}

#fix_bottom_menu {
  z-index: 100;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  transition: bottom 0.4s;
}

#fix_bottom_menu::before {
  z-index: 0;
  display: block;
}

#fix_bottom_menu .menu_list {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 2px 0 0;
}

#fix_bottom_menu .menu_btn .open_btn {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 1;
}

#fix_bottom_menu .menu-item {
  flex: 1 1 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

#fix_bottom_menu .menu-item a {
  display: block;
  color: inherit;
  line-height: 1;
  text-decoration: none;
}

#fix_bottom_menu .menu-item i {
  display: block;
  height: 20px;
  color: inherit;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

#fix_bottom_menu .menu-item i::before {
  display: inline-block;
  color: inherit;
}

#fix_bottom_menu .menu-item.pagetop_btn i {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

#fix_bottom_menu span {
  display: block;
  width: 100%;
  margin-top: 4px;
  color: inherit;
  font-size: 10px;
  line-height: 1;
}

@media (max-width: 959px) {
  #fix_bottom_menu {
    display: block;
  }

  #fix_bottom_menu {
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);
  }
}

:root {
  --swl-radius--2: 2px;
  --swl-radius--4: 4px;
  --swl-radius--8: 8px;
}

.c-categoryList__link,
.c-tagList__link,
.tag-cloud-link {
  padding: 6px 10px;
  border-radius: 16px;
}

.-related .p-postList__thumb,
.is-style-bg_gray,
.is-style-bg_main,
.is-style-bg_main_thin,
.is-style-bg_stripe,
.is-style-border_dg,
.is-style-border_dm,
.is-style-border_sg,
.is-style-border_sm,
.is-style-dent_box,
.is-style-note_box,
.is-style-stitch,
[class*="is-style-big_icon_"],
input[type="number"],
input[type="text"],
textarea {
  border-radius: 4px;
}

.-ps-style-img .p-postList__link,
.-type-thumb .p-postList__link,
.p-postList__thumb {
  z-index: 0;
  overflow: hidden;
  border-radius: 12px;
}

.c-widget .-type-list.-w-ranking .p-postList__item::before {
  top: 2px;
  left: 2px;
  border-radius: 16px;
}

.c-widget .-type-card.-w-ranking .p-postList__item::before {
  border-radius: 0 0 4px 4px;
}

.c-postThumb__cat {
  margin: 0.5em;
  padding: 0 8px;
  border-radius: 16px;
}

.cap_box_ttl {
  border-radius: 2px 2px 0 0;
}

.cap_box_content {
  border-radius: 0 0 2px 2px;
}

.cap_box.is-style-small_ttl .cap_box_content {
  border-radius: 0 2px 2px 2px;
}

.cap_box.is-style-inner .cap_box_content,
.cap_box.is-style-onborder_ttl .cap_box_content,
.cap_box.is-style-onborder_ttl2 .cap_box_content,
.cap_box.is-style-onborder_ttl2 .cap_box_ttl,
.cap_box.is-style-shadow {
  border-radius: 2px;
}

.is-style-more_btn > a,
.p-postList__body::after,
.submit {
  border-radius: 40px;
}

@media (min-width: 960px) {
  #sidebar .-type-list .p-postList__thumb {
    border-radius: 8px;
  }
}

@media (max-width: 959px) {
  .-ps-style-img .p-postList__link,
  .-type-thumb .p-postList__link,
  .p-postList__thumb {
    border-radius: 8px;
  }
}

.l-header__bar {
  position: relative;
  width: 100%;
}

.l-header__bar .c-catchphrase {
  width: fit-content;
  margin-right: auto;
  padding: 4px 0;
  // overflow: hidden;
  color: inherit;
  font-size: 0.765rem;
  line-height: 14px;
  letter-spacing: 0.2px;
  // white-space: nowrap;
}

.l-header__bar .c-iconList .c-iconList__link {
  margin: 0;
  padding: 4px 6px;
}

.l-header__barInner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (min-width: 960px) {
  .-series .l-header__inner {
    display: flex;
    align-items: stretch;
  }

  .-series .l-header__logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // margin-right: 24px;
    // padding: 0 0 16px 0;
  }

  .-series .l-header__logo .c-catchphrase {
    padding: 4px 0;
    font-size: 13px;
  }

  .-series .c-headLogo {
    margin-right: 16px;
  }

  .-series-right .l-header__inner {
    justify-content: space-between;
  }

  .-series-right .c-gnavWrap {
    margin-left: auto;
  }

  .-series-right .w-header {
    margin-left: 12px;
  }

  .-series-left .w-header {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .-series .c-headLogo {
    max-width: 400px;
  }
}

@media (max-width: 1199px) {
  .-series .c-headLogo,
  .l-fixHeader__logo {
    display: none;
  }
}

@media (max-width: 1199px) and (min-width: 959px) {
  .c-gnav {
    padding: 0;
    align-items: center;
    vertical-align: middle;
  }

  .c-gnav > li > a > span {
    padding-top: 20px;
  }

  .l-header__inner,
  .l-fixHeader__inner {
    width: fit-content;
    padding: 0;
  }
}

.c-gnav .sub-menu a::before,
.c-listMenu a::before {
  font-weight: 400;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c-submenuToggleBtn {
  display: none;
}

.c-listMenu a {
  padding: 0.75em 1em 0.75em 1.5em;
  transition: padding 0.25s;
}

.c-listMenu a:hover {
  padding-right: 0.75em;
  padding-left: 1.75em;
}

.c-gnav .sub-menu a::before,
.c-listMenu a::before {
  position: absolute;
  top: 50%;
  left: 2px;
  display: inline-block;
  color: inherit;
  vertical-align: middle;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\e921";
}

.c-listMenu .children,
.c-listMenu .sub-menu {
  margin: 0;
}

.c-listMenu .children a,
.c-listMenu .sub-menu a {
  padding-left: 2.5em;
  font-size: 0.9em;
}

.c-listMenu .children a::before,
.c-listMenu .sub-menu a::before {
  left: 1em;
}

.c-listMenu .children a:hover,
.c-listMenu .sub-menu a:hover {
  padding-left: 2.75em;
}

.c-listMenu .children ul a,
.c-listMenu .sub-menu ul a {
  padding-left: 3.25em;
}

.c-listMenu .children ul a::before,
.c-listMenu .sub-menu ul a::before {
  left: 1.75em;
}

.c-listMenu .children ul a:hover,
.c-listMenu .sub-menu ul a:hover {
  padding-left: 3.5em;
}

.c-gnav li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}

.c-gnav .sub-menu::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  content: "";
}

.c-gnav .sub-menu .sub-menu {
  top: 0;
  left: 100%;
  z-index: -1;
}

.c-gnav .sub-menu a {
  padding-left: 2em;
}

.c-gnav .sub-menu a::before {
  left: 0.5em;
}

.c-gnav .sub-menu a:hover .ttl {
  left: 4px;
}

:root {
  --color_content_bg: var(--color_bg);
  --mv_btn_radius: 40px;
  --mv_slide_animation: no;
}

.c-widget__title.-side {
  border-left: solid 2px var(--color_main);
  padding: 0em 0.75em;
}

.top #content {
  padding-top: 4em;
}

.p-mainVisual__inner {
  height: 55vh;
}

.p-mainVisual .c-filterLayer::before {
  background-color: #000;
  opacity: 0.1;
  content: "";
}

.-type-slider .p-mainVisual__scroll {
  padding-bottom: 16px;
}

.page.-index-off .w-beforeToc {
  display: none;
}

@media screen and (min-width: 960px) {
  :root {
  }

  .p-mainVisual__inner {
    height: 40vw;
  }
}

@media screen and (max-width: 959px) {
  :root {
  }
}

@media screen and (min-width: 600px) {
  :root {
  }
}

@media screen and (max-width: 599px) {
  :root {
  }
}

.swell-block-fullWide__inner.l-container {
  --swl-fw_inner_pad: var(--swl-pad_container, 0px);
}

@media (min-width: 960px) {
  .-sidebar-on .l-content .alignfull,
  .-sidebar-on .l-content .alignwide {
    left: -16px;
    width: calc(100% + 32px);
  }

  .swell-block-fullWide__inner.l-article {
    --swl-fw_inner_pad: var(--swl-pad_post_content, 0px);
  }

  .-sidebar-on .swell-block-fullWide__inner .alignwide {
    left: 0;
    width: 100%;
  }

  .-sidebar-on .swell-block-fullWide__inner .alignfull {
    left: calc(0px - var(--swl-fw_inner_pad, 0)) !important;
    width: calc(100% + var(--swl-fw_inner_pad, 0) * 2) !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.swell-toc-placeholder:empty {
  display: none;
}

.-index-off .p-toc {
  display: none;
}

.p-toc.-modal {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

#main_content .p-toc {
  max-width: 800px;
  margin: 4em auto;
  border-radius: var(--swl-radius--2, 0);
}

#sidebar .p-toc {
  margin-top: -0.5em;
}

.p-toc__ttl {
  position: relative;
  display: block;
  font-size: 1.2em;
  line-height: 1;
  text-align: center;
}

.p-toc__ttl::before {
  display: inline-block;
  margin-right: 0.5em;
  padding-bottom: 2px;
  font-family: icomoon;
  vertical-align: middle;
  content: "\e918";
}

#index_modal .p-toc__ttl {
  margin-bottom: 0.5em;
}

.p-toc__list li {
  line-height: 1.6;
}

.p-toc__list li li {
  font-size: 0.9em;
}

.p-toc__list ol,
.p-toc__list ul {
  padding-bottom: 0.5em;
  padding-left: 0.5em;
}

.p-toc__list .mininote {
  display: none;
}

.post_content .p-toc__list {
  padding-left: 0;
}

#sidebar .p-toc__list {
  margin-bottom: 0;
}

#sidebar .p-toc__list ol,
#sidebar .p-toc__list ul {
  padding-left: 0;
}

.p-toc__link {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

.p-toc__link:hover {
  opacity: 0.8;
}

.p-toc.-simple {
  padding: 1.25em;
  border: solid 1px var(--color_border);
}

.p-toc.-simple .p-toc__ttl {
  margin-bottom: 1em;
  padding: 0 0.5em 0.75em;
  text-align: left;
  border-bottom: dashed 1px var(--color_border);
}

@media (min-width: 960px) {
  #main_content .p-toc {
    width: 92%;
  }
}
body {
  --wp--preset--color--black: #000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #fff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--swl-main: var(--color_main);
  --wp--preset--color--swl-main-thin: var(--color_main_thin);
  --wp--preset--color--swl-gray: var(--color_gray);
  --wp--preset--color--swl-deep-01: var(--color_deep01);
  --wp--preset--color--swl-deep-02: var(--color_deep02);
  --wp--preset--color--swl-deep-03: var(--color_deep03);
  --wp--preset--color--swl-deep-04: var(--color_deep04);
  --wp--preset--color--swl-pale-01: var(--color_pale01);
  --wp--preset--color--swl-pale-02: var(--color_pale02);
  --wp--preset--color--swl-pale-03: var(--color_pale03);
  --wp--preset--color--swl-pale-04: var(--color_pale04);
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 12px;
  --wp--preset--font-size--medium: 18px;
  --wp--preset--font-size--large: 20px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-size--xs: 10px;
  --wp--preset--font-size--huge: 28px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
  gap: 0.5em;
}

body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
  display: flex;
}

body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}

body .is-layout-flex > * {
  margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

.has-black-color {
  color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}
