@use "global" as *;

.c-infoBar__btn,
.is-style-btn_shiny a {
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: opacity 0.25s, -webkit-transform 0.25s;
  transition: opacity 0.25s, transform 0.25s;
  transition: opacity 0.25s, transform 0.25s, -webkit-transform 0.25s;
}
.c-infoBar__btn::before,
.is-style-btn_shiny a::before {
  position: absolute;
  top: 50%;
  left: -120px;
  width: 80px;
  height: 200px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-transform: translateY(-50%) rotate(25deg);
  transform: translateY(-50%) rotate(25deg);
  content: "";
}
.c-infoBar__btn::after,
.is-style-btn_shiny a::after {
  position: absolute;
  top: 0;
  left: -100px;
  width: 50px;
  height: 100%;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 0, #fff 100%);
  -webkit-animation-name: shiny_btn;
  animation-name: shiny_btn;
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  content: "";
}
.c-infoBar__btn:hover,
.is-style-btn_shiny a:hover {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}
.c-infoBar__btn:hover::before,
.is-style-btn_shiny a:hover::before {
  left: 110%;
  transition: 0.25s ease-in-out;
}
.c-infoBar__btn:hover::after,
.is-style-btn_shiny a:hover::after {
  -webkit-animation: none;
  animation: none;
}
#fix_bottom_menu::before,
.c-infoBar::before,
.c-mvBtn__btn::before,
.l-fixHeader::before,
.p-spMenu__inner::before,
.post_content .td_to_th_ tbody tr > :first-child::before,
.post_content tbody th::before,
[data-type="type1"] .p-blogCard__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.wp-block-image.is-style-shadow img,
.wp-block-video.is-style-shadow video,
img.shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 8px -4px rgba(0, 0, 0, 0.2);
}
.w-pageTop,
.w-singleBottom,
.w-singleTop {
  margin: 2em 0;
}
.c-categoryList::before,
.c-tagList::before,
code.dir_name::before,
code.file_name::before {
  font-weight: 400;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.c-categoryList__separation::after,
.p-breadcrumb__item::after {
  display: block;
  box-sizing: content-box;
  width: 4px;
  height: 4px;
  color: inherit;
  border-top: solid 1px currentColor;
  border-right: solid 1px currentColor;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0.8;
  content: "";
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
::after,
::before {
  box-sizing: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}
b,
strong {
  font-weight: 700;
}
sub,
sup {
  font-size: smaller;
}
ruby > rt {
  font-size: 50%;
}
main {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote::after,
blockquote::before,
q::after,
q::before {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  word-break: break-all;
}
img {
  max-width: 100%;
  height: auto;
  border-style: none;
}
video {
  outline: 0;
}
code,
pre {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
svg:not([fill]) {
  fill: currentColor;
}
[hidden] {
  display: none;
}

.cms-preview-form button, /*--20020829 add -- */
.cms-preview-form input,
.cms-preview-form optgroup,
.cms-preview-form select,
.cms-preview-form textarea {
  width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  display: inline-block;
  cursor: pointer;
}
[role="button"] {
  cursor: pointer;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  padding: 0.25em 0.5em;
  color: #333;
  background-color: #f7f7f7;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 0;
}
[type="color"] {
  padding: 0;
}
[type="range"] {
  vertical-align: middle;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes FadeIn {
  100% {
    opacity: 1;
  }
}
@keyframes FadeIn {
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes FadeInFromX {
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes FadeInFromX {
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes FadeInFromY {
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes FadeInFromY {
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes flowing_text {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes flowing_text {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes shiny_btn {
  0% {
    -webkit-transform: scale(0) rotate(25deg);
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }
  84% {
    -webkit-transform: scale(0) rotate(25deg);
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }
  85% {
    -webkit-transform: scale(1) rotate(25deg);
    transform: scale(1) rotate(25deg);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(50) rotate(25deg);
    transform: scale(50) rotate(25deg);
    opacity: 0;
  }
}
@keyframes shiny_btn {
  0% {
    -webkit-transform: scale(0) rotate(25deg);
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }
  84% {
    -webkit-transform: scale(0) rotate(25deg);
    transform: scale(0) rotate(25deg);
    opacity: 0;
  }
  85% {
    -webkit-transform: scale(1) rotate(25deg);
    transform: scale(1) rotate(25deg);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(50) rotate(25deg);
    transform: scale(50) rotate(25deg);
    opacity: 0;
  }
}
@-webkit-keyframes leftToRight {
  0% {
    -webkit-transform: scale(1.1) translateX(-1%);
    transform: scale(1.1) translateX(-1%);
  }
  100% {
    -webkit-transform: scale(1.1) translateX(1%);
    transform: scale(1.1) translateX(1%);
  }
}
@keyframes leftToRight {
  0% {
    -webkit-transform: scale(1.1) translateX(-1%);
    transform: scale(1.1) translateX(-1%);
  }
  100% {
    -webkit-transform: scale(1.1) translateX(1%);
    transform: scale(1.1) translateX(1%);
  }
}
@-webkit-keyframes zoomUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes zoomUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@-webkit-keyframes FloatVertical {
  0% {
    -webkit-transform: translate3d(0, 4px, 0);
    transform: translate3d(0, 4px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 4px, 0);
    transform: translate3d(0, 4px, 0);
  }
}
@keyframes FloatVertical {
  0% {
    -webkit-transform: translate3d(0, 4px, 0);
    transform: translate3d(0, 4px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 4px, 0);
    transform: translate3d(0, 4px, 0);
  }
}
@-webkit-keyframes FloatHorizontal {
  0% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes FloatHorizontal {
  0% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
:root {
  --swl-sidebar_width: 304px;
  --swl-sidebar_margin: 52px;
  --swl-pad_post_content: 0px;
  --swl-pad_container: 4vw;
  --swl-color_hov_gray: rgba(150, 150, 150, 0.05);
  --swl-color_border: rgba(200, 200, 200, 0.5);
  --swl-color_shadow: rgba(0, 0, 0, 0.12);
  --swl-box_shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 4px -4px rgba(0, 0, 0, 0.1);
  --swl-img_shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 8px -4px rgba(0, 0, 0, 0.2);
  --swl-btn_shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 8px -4px rgba(0, 0, 0, 0.2);
  --swl-h2-margin--x: -2vw;
}
@font-face {
  font-weight: 400;
  font-family: icomoon;
  font-style: normal;
  src: url(../fonts/icomoon.ttf?7ojy2d) format("truetype"),
    url(../fonts/icomoon.woff?7ojy2d) format("woff"),
    url(../fonts/icomoon.svg?7ojy2d#icomoon) format("svg");
}
[class*=" icon-"]::before,
[class^="icon-"]::before {
  font-weight: 400;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-clipboard-copy::before {
  content: "\e93b";
}
.icon-clipboard-copied::before {
  content: "\e93c";
}
.icon-swell::before {
  content: "\e936";
}
.icon-amazon::before {
  color: #f90;
  content: "\e91e";
}
.icon-room::before {
  content: "\e93d";
}
.icon-codepen::before {
  content: "\e900";
}
.icon-facebook::before {
  content: "\e901";
}
.icon-feedly::before {
  color: #2bb24c;
  content: "\e902";
}
.icon-github::before {
  content: "\e903";
}
.icon-googleplus::before {
  color: #dc4e41;
  content: "\e904";
}
.icon-hatebu::before {
  content: "\e905";
}
.icon-instagram::before {
  color: #e4405f;
  content: "\e906";
}
.icon-line::before {
  color: #00c300;
  content: "\e907";
}
.icon-medium::before {
  content: "\e908";
}
.icon-pinterest::before {
  color: #bd081c;
  content: "\e909";
}
.icon-pocket::before {
  color: #ef3f56;
  content: "\e90a";
}
.icon-rss::before {
  color: orange;
  content: "\e90b";
}
.icon-tumblr::before {
  color: #36465d;
  content: "\e90c";
}
.icon-twitter::before {
  color: #1da1f2;
  content: "\e90d";
}
.icon-wordpress::before {
  content: "\e90e";
}
.icon-youtube::before {
  color: red;
  content: "\e90f";
}
.icon-more_arrow::before {
  content: "\e930";
}
.icon-batsu::before,
.icon-cross::before,
.is-style-bad_list li::before,
.is-style-big_icon_bad::before,
.is-style-big_icon_batsu::before {
  content: "\e91f";
}
.icon-circle::before,
.is-style-good_list li::before {
  content: "\ea56";
}
.icon-check::before,
.icon-checkmark::before,
.is-style-big_icon_check::before,
.is-style-big_icon_good::before,
.is-style-check_list li::before {
  content: "\e923";
}
.icon-posted::before {
  content: "\e931";
}
.icon-modified::before {
  content: "\e92d";
}
.icon-search::before {
  content: "\e92e";
}
.icon-close-thin::before {
  content: "\e920";
}
.icon-menu-thin::before {
  content: "\e91b";
}
.icon-info::before {
  content: "\e91d";
}
.icon-alert::before {
  content: "\e916";
}
.icon-hatena::before {
  content: "\e917";
}
.icon-index::before {
  content: "\e918";
}
.icon-arrow_drop_down::before {
  content: "\e5c5";
}
.icon-arrow_drop_up::before {
  content: "\e5c7";
}
.icon-flag::before {
  content: "\e153";
}
.icon-border_color::before {
  content: "\e22b";
}
.icon-cart::before,
.icon-shopping_cart::before {
  content: "\e939";
}
.icon-person::before {
  content: "\e7fd";
}
.icon-settings::before {
  content: "\e8b8";
}
.icon-thumb_down::before {
  content: "\e8db";
}
.icon-thumb_up::before {
  content: "\e8dc";
}
.icon-chevron-small-down::before {
  content: "\e910";
}
.icon-chevron-small-left::before {
  content: "\e911";
}
.icon-chevron-small-right::before {
  content: "\e921";
}
.icon-chevron-small-up::before {
  content: "\e912";
}
.icon-book::before {
  content: "\e91a";
}
.icon-megaphone::before {
  content: "\e913";
}
.icon-minus::before {
  content: "\e919";
}
.icon-plus::before {
  content: "\e91c";
}
.icon-download::before {
  content: "\e914";
}
.icon-pen::before {
  content: "\e934";
}
.icon-contact::before,
.icon-envelope::before,
.icon-mail::before,
.icon-markunread::before {
  content: "\e92c";
}
.icon-light-bulb::before {
  content: "\e915";
}
.icon-lock-closed::before {
  content: "\e932";
}
.icon-lock-open::before {
  content: "\e933";
}
.icon-home::before {
  content: "\e922";
}
.icon-quill::before {
  content: "\e929";
}
.icon-books::before {
  content: "\e925";
}
.icon-file-empty::before,
code.file_name::before {
  content: "\e924";
}
.icon-file-text2::before {
  content: "\e926";
}
.icon-file-picture::before {
  content: "\e927";
}
.icon-file-music::before {
  content: "\e928";
}
.icon-file-video::before {
  content: "\e92a";
}
.icon-file-zip::before {
  content: "\e92b";
}
.c-categoryList::before,
.icon-folder::before,
code.dir_name::before {
  content: "\e92f";
}
.icon-price-tag::before {
  content: "\e935";
}
.icon-phone1::before,
.icon-phone::before {
  content: "\e942";
}
.icon-bubble::before {
  content: "\e96b";
}
.icon-bubbles::before {
  content: "\e970";
}
.icon-quotes-left::before {
  content: "\e977";
}
.icon-quotes-right::before {
  content: "\e978";
}
.icon-link::before {
  content: "\e9c5";
}
.icon-attachment::before {
  content: "\e9cd";
}
.icon-eye::before {
  content: "\e9ce";
}
.icon-bookmarks::before {
  content: "\e9d3";
}
.icon-star-empty::before {
  content: "\e9d7";
}
.icon-star-half::before {
  content: "\e9d8";
}
.icon-star-full::before {
  content: "\e9d9";
}
.icon-heart::before {
  content: "\e9da";
}
.icon-blocked::before {
  content: "\ea0e";
}
.icon-share::before {
  content: "\ea82";
}
.icon-film::before {
  content: "\e93a";
}
.icon-headphones::before {
  content: "\e938";
}
.icon-image::before {
  content: "\e937";
}
body {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  color: var(--color_text);
  font-size: 15px;
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: var(--color_bg);
  -webkit-text-size-adjust: 100%;
}
a {
  color: var(--color_link);
}
[data-spmenu="opened"] #wpadminbar {
  z-index: 1;
}
#lp-content .post_content,
#main_content .post_content {
  font-size: var(--swl-content_font_size, 16px);
}
#fix_bottom_menu {
  position: fixed;
  bottom: -60px;
  left: 0;
  display: none;
}
.ad_area {
  min-height: 80px;
  margin: 3em auto;
  border: dashed 1px rgba(200, 200, 200, 0.5);
}
.ad_area.content_top {
  margin-top: 0;
}
.-index-off .widget_swell_index,
.-index-off [data-onclick="toggleIndex"] {
  display: none;
}
.-index-off .widget_swell_index + .c-widget {
  margin-top: 0 !important;
}
.l-article {
  max-width: 900px;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
}
.-sidebar-off .l-article,
.lp-content .l-article {
  margin-right: auto;
  margin-left: auto;
}
#body_wrap {
  position: relative;
  z-index: 1;
  max-width: 100%;
}
#body_wrap.-bg-fix {
  z-index: 1;
}
#body_wrap.-bg-fix::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}
.admin-bar {
  --swl-adminbarH: 32px;
}
.l-container {
  max-width: calc(var(--container_size, 0px) + 2 * var(--swl-pad_container, 0px));
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--swl-pad_container, 0);
  padding-left: var(--swl-pad_container, 0);
}
.l-content {
  position: relative;
  z-index: 1;
  margin: 0 auto 6em;
  padding-top: 2em;
}
.l-fixHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  margin-top: var(--swl-adminbarH, 0);
  -webkit-transform: translateY(calc(-1 * var(--swl-fix_headerH, 100px)));
  transform: translateY(calc(-1 * var(--swl-fix_headerH, 100px)));
  visibility: hidden;
  opacity: 0;
}
.l-fixHeader[data-ready] {
  transition: all 0.5s;
}
.l-fixHeader::before {
  display: block;
  background: var(--color_header_bg);
}
.c-smallNavTitle {
  color: inherit;
  font-size: 10px;
  line-height: 1;
  opacity: 0.6;
}
.sub-menu .c-smallNavTitle {
  display: none;
}
.c-gnav {
  display: flex;
  height: 100%;
  text-align: center;
  /*--  padding-bottom: 16px;  20230824--*/
}
.c-gnav a {
  text-decoration: none;
}
.c-gnav .ttl {
  position: relative;
  z-index: 1;
  color: inherit;
  line-height: 1;
}
.c-gnav > .menu-item {
  position: relative;
  height: 51px; /*--  20230824 add --*/
}
.c-gnav > .menu-item:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.c-gnav > .menu-item > a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /*-- height: 100%; 20230824--*/
  padding: 25px 10px 25px 10px; /*--  20230824 add --*/
  color: inherit;
  white-space: nowrap;
}
.c-gnav > .menu-item > a .ttl {
  display: block;
  font-size: 1.059rem;
  font-weight: normal;
  color: #707070;
}
.c-gnav > .menu-item > a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  transition: width 0.25s, height 0.25s, -webkit-transform 0.25s;
  transition: transform 0.25s, width 0.25s, height 0.25s;
  transition: transform 0.25s, width 0.25s, height 0.25s, -webkit-transform 0.25s;
  content: "";
}
.c-gnav > .menu-item > a .c-submenuToggleBtn {
  display: none;
}
.c-gnav > .menu-item > .sub-menu {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.c-gnav .sub-menu {
  position: absolute;
  z-index: 1;
  width: 15em;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s, visibility 0.25s;
}
.c-gnav .sub-menu li {
  position: relative;
}
.c-gnav .sub-menu .ttl {
  position: relative;
  left: 0;
  font-size: 0.9em;
  transition: left 0.25s;
}
.c-gnav .sub-menu a {
  position: relative;
  display: block;
  padding: 0.5em 0.75em;
  color: inherit;
}
.c-gnav .sub-menu a:hover {
  background-color: var(--swl-color_hov_gray);
}
.c-gnav__sBtn {
  width: auto;
}
.c-gnav__sBtn .icon-search {
  font-size: 20px;
}
.l-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: var(--color_header_bg);
  border-top: 16px solid #ff8c9b;
}
.l-header__inner {
  position: relative;
  z-index: 1;
  width: 100%;
  color: var(--color_header_text);
}
.l-header__inner .c-catchphrase {
  letter-spacing: 0.2px;
}
.l-header__logo {
  line-height: 1;
}
.c-headLogo.-txt {
  display: flex;
  align-items: center;
}
.c-headLogo__link {
  display: block;
  text-decoration: none;
}
.-img .c-headLogo__link {
  height: 100%;
}
.-txt .c-headLogo__link {
  display: block;
  color: inherit;
  font-weight: 700;
  font-size: 5vw;
}
.c-headLogo__img {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.w-header {
  display: flex;
  align-items: center;
}
.l-header__customBtn,
.l-header__menuBtn {
  width: var(--logo_size_sp);
  height: var(--logo_size_sp);
  line-height: 1.25;
  text-align: center;
}
.l-mainContent {
  position: static !important;
  width: 100%;
}
.-sidebar-on .l-mainContent {
  max-width: 100%;
}
.l-mainContent__inner > :first-child,
.l-parent > :first-child,
.post_content > :first-child {
  margin-top: 0 !important;
}
.l-mainContent__inner > :last-child,
.l-parent > :last-child,
.post_content > :last-child {
  margin-bottom: 0 !important;
}
.l-scrollObserver {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  height: 1px;
  visibility: hidden;
  pointer-events: none;
}
#sidebar {
  position: relative;
}
.c-blogLink {
  line-height: 1.5;
  text-decoration: none;
}
.c-blogLink__icon {
  display: block;
  display: inline-flex;
  margin-right: 3px;
  padding: 4px;
  text-decoration: none;
  border: solid 1px;
  border-radius: 50%;
}
.c-blogLink__icon::before {
  font-size: 0.8em;
}
.c-blogLink__text {
  text-decoration: underline;
}
.c-listMenu li {
  position: relative;
  margin: 0;
}
.c-listMenu a {
  position: relative;
  display: block;
  color: inherit;
  line-height: 1.4;
  text-decoration: none;
  border-bottom: solid 1px var(--swl-color_border);
}
.c-listMenu a:hover {
  background-color: var(--swl-color_hov_gray);
}
.c-categoryList {
  color: var(--color_main);
}
.c-categoryList::before {
  margin-right: 0.5em;
}
// .c-tagList::before {
//   margin-right: 0.5em;
//   color: inherit;
//   content: "\e935";
// }
.c-categoryList__link,
.c-tagList__link {
  display: block;
  margin: 0.25em 0.25em 0.25em 0;
  padding: 4px 0.5em;
  line-height: 1;
  text-decoration: none;
}
.c-tagList__link {
  color: #333;
  background: #f7f7f7;
}
.c-categoryList__link {
  color: #fff;
  background-color: var(--color_main);
}
.l-mainContent .c-categoryList__link.-current {
  color: inherit;
  background: 0 0;
  box-shadow: inset 0 0 0 1px currentColor;
}
.l-topTitleArea .c-categoryList__link.-current {
  padding: 0;
  border: none;
}
.c-categoryList__separation {
  display: block;
  margin: 0.25em 0.25em 0.25em 0;
  padding: 4px 0.5em;
  line-height: 1;
}
.c-categoryList__separation::after {
  position: relative;
  left: -2px;
}
.c-filterLayer::after,
.c-filterLayer::before,
.c-filterLayer__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.c-filterLayer {
  z-index: 0;
}
.c-filterLayer::before {
  z-index: 1;
}
.c-filterLayer::after {
  z-index: 2;
  display: block;
}
.c-filterLayer.-texture-dot::after {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../img/texture/dot.png);
  background-size: 2px;
  opacity: 0.5;
  content: "";
}
.c-filterLayer.-texture-brushed::after {
  background-image: url(../img/texture/brushed-alum.png);
  opacity: 0.8;
  content: "";
}
.c-filterLayer__img {
  z-index: 0;
}
.-filter-blur > .c-filterLayer__img {
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  -webkit-filter: blur(4px);
  filter: blur(4px);
}
.-filter-glay > .c-filterLayer__img {
  -webkit-filter: grayscale(60%);
  filter: grayscale(60%);
}
.c-fixBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 52px;
  height: 52px;
  overflow: hidden;
  color: var(--color_main);
  line-height: 1;
  text-align: center;
  background: #fff;
  border: solid 1px var(--color_main);
  border-radius: var(--swl-radius--8, 0);
  box-shadow: var(--swl-box_shadow);
  cursor: pointer;
  opacity: 0.75;
  transition: all 0.25s;
}
.c-fixBtn + .c-fixBtn {
  margin-top: 16px;
}
.c-fixBtn__icon {
  display: block;
  font-size: 20px;
}
#pagetop .c-fixBtn__icon::before {
  font-size: 24px;
}
.c-fixBtn__label {
  display: block;
  font-size: 10px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
}
#fix_tocbtn .c-fixBtn__label {
  margin-top: 2px;
}
#pagetop .c-fixBtn__label {
  margin-top: -4px;
}
#fix_tocbtn[data-has-text="1"] .c-fixBtn__icon {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
#pagetop[data-has-text="1"] .c-fixBtn__icon {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.c-iconBtn {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: inherit;
  text-decoration: none;
}
.c-iconBtn .-menuBtn {
  z-index: 1;
}
.c-iconBtn__icon {
  display: block;
  font-size: 24px;
}
.p-spMenu__closeBtn .c-iconBtn__icon {
  font-size: 20px;
}
.c-iconBtn__icon::before {
  display: block;
}
.c-iconBtn__label {
  display: block;
  width: 100%;
  font-size: 10px;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.c-iconList {
  display: flex;
  flex-wrap: wrap;
}
.widget_swell_prof_widget .c-iconList,
.widget_swell_sns_links .c-iconList {
  justify-content: center;
}
.l-footer__foot .c-iconList {
  justify-content: center;
  margin-bottom: 8px;
}
.c-iconList__link {
  display: block;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  margin: 0 3px;
  padding: 2px;
  color: inherit;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: 0 0;
  border: none;
  box-shadow: none;
}
.c-iconList__icon {
  display: block;
  line-height: 1;
}
.c-iconList__icon::before {
  color: inherit;
}
.is-style-circle .c-iconList__link {
  margin: 4px;
  padding: 5px;
  border: solid 1px;
  border-radius: 50%;
}
.is-style-circle .c-iconList__icon {
  padding-left: 0.5px;
}
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s, visibility 0.5s;
}
[data-loaded="false"] .c-modal {
  display: none;
}
.c-modal:not(.is-opened) {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.c-mvBtn {
  white-space: nowrap;
  position: absolute;
  bottom: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  // margin: 2em auto 0;
  text-align: center;
}
.u-ta-l .c-mvBtn {
  margin-left: 0;
}
.u-ta-r .c-mvBtn {
  margin-right: 0;
}
.c-mvBtn__btn {
  position: relative;
  display: block;
  padding: 0.75em 2.5em;
  overflow: hidden;
  font-size: 3.2vw;
  letter-spacing: 1px;
  text-decoration: none;
  text-shadow: none;
  background-color: var(--color_main);
  border-radius: var(--mv_btn_radius);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s;
}
.-btn-n > .c-mvBtn__btn {
  color: #fff;
}
.c-mvBtn__btn::before {
  display: block;
  background: linear-gradient(rgba(255, 255, 255, 0.2), #fff);
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.25s;
}
.c-mvBtn__btn:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.c-mvBtn__btn:hover::before {
  opacity: 0.2;
}
.-btn-b > .c-mvBtn__btn {
  background: 0 0;
  border: solid 1px;
}
.c-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.c-pagination {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 3em 0;
  font-size: 13px;
}
[class*="page-numbers"] {
  position: relative;
  display: block;
  box-sizing: content-box;
  width: auto;
  min-width: 36px;
  height: 36px;
  margin: 6px;
  padding: 0;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  transition: color 0.25s, background-color 0.25s;
}
[class*="page-numbers"].current,
[class*="page-numbers"]:hover {
  color: #fff;
  background-color: var(--color_main);
}
.c-pagination__dot {
  margin: 0 6px;
  color: #aaa;
}
.c-postTitle {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.c-postTitle__ttl {
  width: 100%;
  font-size: 5vw;
  line-height: 1.4;
}
.c-postTitle__date {
  margin-right: 0.75em;
  padding: 0.5em 0.75em 0.5em 0;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  border-right: solid 1px var(--color_border);
}
.c-postTitle__date .__y {
  display: block;
  margin-bottom: 4px;
  font-size: 3vw;
  line-height: 1;
}
.c-postTitle__date .__md {
  display: block;
  font-size: 4.2vw;
}
.p-postList .c-postTitle {
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto 1em;
}
.p-postList .c-postTitle__date .__md,
.p-postList .c-postTitle__ttl {
  font-size: 4vw;
}
.c-reviewStars i {
  color: #ffc82e;
}
.post_content > .c-reviewStars {
  display: block;
}
.c-searchForm {
  position: relative;
}
.c-searchForm__s {
  width: 100%;
  padding: 4px 44px 4px 8px;
  line-height: 2;
  background-color: #fff;
}
.c-searchForm__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  overflow: hidden;
  border-radius: var(--swl-radius--4, 0);
}
.c-searchForm__submit::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.w-header .c-searchForm__s {
  font-size: 14px;
  line-height: 20px;
}
.w-header .c-searchForm__submit {
  width: 30px;
}
.w-header .c-searchForm__submit::before {
  font-size: 16px;
}
.skip-link {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  text-decoration: none;
  opacity: 0;
  pointer-events: none;
}
.skip-link:focus {
  top: 8px;
  left: 8px;
  z-index: 1000;
  display: block;
  width: auto;
  height: auto;
  padding: 16px 24px;
  color: #21759b;
  font-weight: 700;
  font-size: 14px;
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  opacity: 1;
  pointer-events: auto;
}
.c-postAuthor {
  display: flex;
  align-items: center;
}
.c-postAuthor.is-link {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.25s;
}
.c-postAuthor.is-link:hover {
  opacity: 0.75;
}
.c-postAuthor__figure {
  width: 20px;
  height: 20px;
  margin-right: 0.25em;
  margin-left: 1px;
  overflow: hidden;
  border-radius: 50%;
}
.c-postAuthor__figure .avatar {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}
.c-postThumb {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.c-postThumb__figure {
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  -webkit-transform: perspective(0);
  transform: perspective(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.c-postThumb__figure::before {
  display: block;
  height: 0;
  padding-top: var(--card_posts_thumb_ratio);
  content: "";
}
.-type-list .c-postThumb__figure::before,
.-type-list2 .c-postThumb__figure::before {
  padding-top: var(--list_posts_thumb_ratio);
}
.-type-big .c-postThumb__figure::before {
  padding-top: var(--big_posts_thumb_ratio);
}
.-type-thumb .c-postThumb__figure::before {
  padding-top: var(--thumb_posts_thumb_ratio);
}
.c-postThumb__img {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(1.01) perspective(0);
  transform: scale(1.01) perspective(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}
.c-postThumb__cat {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: inline-block;
  padding: 0 0.75em;
  overflow: hidden;
  font-size: 10px;
  line-height: 2;
  white-space: nowrap;
  background-color: var(--color_main);
  -webkit-transform: perspective(0);
  transform: perspective(0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.-type-list .c-postThumb__cat,
.-type-list2 .c-postThumb__cat {
  right: auto;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.c-postThumb__cat::before {
  margin-right: 0.5em;
}
.c-postTimes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.c-postTimes__modified,
.c-postTimes__posted {
  margin-right: 0.5em;
}
.c-postTimes__modified::before,
.c-postTimes__posted::before {
  position: relative;
  top: 1px;
  margin-right: 0.25em;
}
.c-rssSite {
  margin-right: 0.5rem;
}
.c-rssSite__favi {
  display: block;
  width: 14px;
  height: 14px;
  margin-right: 0.25rem;
}
.c-rssAuthor {
  margin-left: 0 !important;
}
.p-blogCard {
  display: block;
  width: 100%;
  max-width: 880px;
  margin: 2.5em auto 2em;
  cursor: pointer;
  transition: box-shadow 0.25s;
}
.wp-block-embed > .p-blogCard {
  margin: 0 auto;
}
.p-blogCard:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 12px 28px -12px rgba(0, 0, 0, 0.05);
}
.p-blogCard:hover .c-postThumb__img {
  opacity: 1;
}
.p-blogCard__caption {
  position: absolute;
  top: -1em;
  left: 16px;
  z-index: 1;
  display: inline-block;
  padding: 0 0.5em;
  font-size: 11px;
  line-height: 2;
  background: var(--color_content_bg);
  border-radius: 2px;
}
.p-blogCard__caption::before {
  display: inline-block;
  margin-right: 3px;
  font-family: icomoon;
  content: "\e923";
}
.-external .p-blogCard__caption::before {
  content: "\e91a";
}
.p-blogCard__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 16px;
  transition: box-shadow 0.25s;
}
.-noimg > .p-blogCard__inner {
  min-height: 5em;
}
.p-blogCard__thumb {
  flex-shrink: 0;
  width: 30%;
  margin: 0 1em 0 0;
}
.p-blogCard__thumb .c-postThumb__figure {
  position: relative;
  width: 100%;
  margin: 0;
}
.p-blogCard__thumb .c-postThumb__figure::before {
  padding-top: 56.25%;
}
.p-blogCard__body {
  flex: 1 1 auto;
  margin: 0;
}
.p-blogCard__title {
  display: block;
  color: inherit;
  font-weight: 400;
  line-height: 1.4;
  text-decoration: none;
}
.p-blogCard__excerpt {
  display: block;
  margin-top: 0.5em;
  font-size: 0.8em;
  line-height: 1.4;
  word-break: break-word;
  opacity: 0.8;
}
[data-type="type1"] .p-blogCard__caption {
  right: auto;
  bottom: auto;
}
[data-type="type1"] .p-blogCard__inner {
  padding-top: 21px;
}
[data-type="type1"] .p-blogCard__inner::before {
  display: block;
  border: solid 1px currentColor;
  border-radius: var(--swl-radius--2, 0);
  pointer-events: none;
}
[data-type="type2"] .p-blogCard__caption {
  right: auto;
  bottom: auto;
  padding: 0 0.75em;
  color: #fff;
  background: var(--color_main);
}
[data-type="type2"] .p-blogCard__inner {
  padding-top: 24px;
  border: solid 1px var(--color_main);
  border-radius: 2px;
}
[data-type="type3"] .p-blogCard__caption {
  top: auto;
  right: 4px;
  bottom: 2px;
  left: auto;
  font-size: 10px;
  background: 0 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  opacity: 0.8;
}
[data-type="type3"] .p-blogCard__inner {
  color: #333;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.08);
}
.p-breadcrumb {
  position: relative;
  margin: 0 auto;
  padding: 8px 0;
}
.p-breadcrumb.-bg-on {
  background: #f7f7f7;
}
.p-breadcrumb:not(.-bg-on) {
  padding-top: 12px;
}
.-body-solid .p-breadcrumb.-bg-on {
  box-shadow: inset 0 -1px 8px rgba(0, 0, 0, 0.06);
}
.-frame-on .p-breadcrumb,
.c-infoBar + .p-breadcrumb,
.c-infoBar + [data-barba="container"] > .p-breadcrumb,
.l-topTitleArea + .p-breadcrumb {
  padding-top: 12px;
  background: 0 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.-frame-on .p-breadcrumb {
  padding-bottom: 12px;
}
.p-breadcrumb__list {
  display: flex;
  width: 100%;
  overflow: hidden;
  list-style: none;
}
.p-breadcrumb__item {
  position: relative;
  margin-right: 10px;
  padding-right: 12px;
}
.p-breadcrumb__item::after {
  position: absolute;
  top: calc(50% - 2px);
  right: 0;
}
.p-breadcrumb__item:last-child::after {
  content: none;
}
.p-breadcrumb__item:last-child span {
  opacity: 0.8;
}
.p-breadcrumb__text {
  display: block;
  color: inherit;
  font-size: 10px;
  white-space: nowrap;
  text-decoration: none;
}
.p-breadcrumb__text::before {
  margin-right: 3px;
}
.p-breadcrumb__text:hover span {
  opacity: 0.76;
}
.single span.p-breadcrumb__text {
  display: none;
}
.p-fixBtnWrap {
  position: fixed;
  right: 1em;
  bottom: 1.25em;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s, -webkit-transform 0.3s;
}
[data-loaded="false"] .p-fixBtnWrap {
  display: none;
}
[data-scrolled="true"] .p-fixBtnWrap {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.p-postList.-type-simple {
  border-top: solid 1px var(--swl-color_border);
}
.p-postList.-type-card,
.p-postList.-type-thumb {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5em;
  margin-left: -0.5em;
}
.-frame-off .p-postList.-type-list2 {
  padding-top: 1.5em;
  border-top: solid 1px var(--swl-color_border);
}
.c-widget .p-postList.-type-card {
  margin-bottom: -1.5em;
}
.-type-simple .p-postList__item {
  margin-top: 0;
  margin-bottom: 0;
}
.-type-list .p-postList__item {
  margin-top: 0;
  margin-bottom: 2em;
}
.-type-list2 .p-postList__item {
  margin-top: 0;
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: solid 1px var(--swl-color_border);
}
.-type-big .p-postList__item {
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.-type-big .p-postList__item:not(:last-child) {
  margin-bottom: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid var(--swl-color_border);
}
.-type-card .p-postList__item {
  width: 50%;
  margin-top: 0;
  margin-bottom: 2.5em;
  padding: 0 0.5em;
}
.-type-thumb .p-postList__item {
  width: 50%;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 0 0.5em;
}
.is-first-big > .p-postList__item:first-child {
  width: 100%;
}
.c-widget .-type-card .p-postList__item {
  margin-bottom: 2em;
}
.c-widget .-type-list .p-postList__item {
  margin-bottom: 1.5em;
}
.p-postList__link {
  position: relative;
  z-index: 0;
  display: block;
  height: 100%;
  color: inherit;
  text-decoration: none;
}
.p-postList__link:hover .c-postThumb::before {
  opacity: 0.2;
}
.p-postList__link:hover .c-postThumb__img {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
.-type-simple .p-postList__link {
  display: block;
  padding: 1em 0.25em 1em;
  border-bottom: solid 1px var(--swl-color_border);
  transition: background-color 0.25s;
}
.-type-simple .p-postList__link:hover {
  background-color: var(--swl-color_hov_gray);
}
.-type-list .p-postList__link,
.-type-list2 .p-postList__link {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.-type-list2 .p-postList__link {
  padding: 0 0.25em;
}
.-type-list2 .p-postList__item:nth-child(2n) .p-postList__link {
  flex-direction: row-reverse;
}
.-type-card .p-postList__thumb {
  width: 100%;
}
.-type-list .p-postList__thumb,
.-type-list2 .p-postList__thumb {
  width: 36%;
}
.-type-thumb .p-postList__thumb {
  width: 100%;
  box-shadow: none;
}
.p-postList__thumb::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--color_gradient1), var(--color_gradient2));
  -webkit-transform: perspective(0);
  transform: perspective(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s;
  content: "";
}
.p-postList__body {
  position: relative;
  transition: opacity 0.25s;
}
.-type-big .p-postList__body,
.-type-card .p-postList__body {
  padding-top: 1em;
}
.-ps-style-normal .p-postList__item:hover .p-postList__body,
.-type-card .p-postList__item:hover .p-postList__body,
.-type-list .p-postList__item:hover .p-postList__body {
  opacity: 0.75;
}
.-type-list .p-postList__body,
.-type-list2 .p-postList__body {
  width: 60%;
}
.-type-thumb .p-postList__body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75em 0.75em;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
.-type-big .p-postList__body::after,
.-type-list2 .p-postList__body::after {
  display: block;
  width: 100%;
  margin: 2em auto 0;
  padding: 1em 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: center;
  box-shadow: 0 0 0 1px var(--swl-color_border);
  opacity: 0.75;
  transition: color 0.25s, background-color 0.25s;
}
.-type-list2 .p-postList__body::after {
  display: inline-block;
  width: auto;
  max-width: 100%;
  margin-top: 2em;
  padding: 0.5em 3em;
}
.p-postList__link:hover .p-postList__body::after {
  color: #fff;
  background: var(--color_main);
  box-shadow: none;
  opacity: 1;
}
.p-postList .p-postList__title {
  margin: 0;
  font-weight: 700;
  line-height: 1.5;
}
.-type-card .p-postList__title,
.-type-list .p-postList__title,
.-type-list2 .p-postList__title {
  font-size: 3.6vw;
}
.-type-simple .p-postList__title {
  font-size: 1em;
}
.p-postList__excerpt {
  line-height: 1.5;
}
.-type-card .p-postList__excerpt {
  margin-top: 0.5em;
  font-size: 3.2vw;
}
.-type-list .p-postList__excerpt,
.-type-list2 .p-postList__excerpt {
  margin-top: 0.5em;
  font-size: 3.4vw;
}
.-type-big .p-postList__excerpt {
  padding-bottom: 0.5em;
  line-height: 1.6;
}
#sidebar .p-postList__excerpt,
.-related .p-postList__excerpt,
.-type-thumb .p-postList__excerpt {
  display: none;
}
.p-postList__meta {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.75em;
  font-size: 10px;
  line-height: 1.25;
}
.p-postList__title + .p-postList__meta {
  margin-top: 0.25em;
}
.p-postList__meta ::before {
  margin-right: 0.25em;
}
.-type-simple .p-postList__meta {
  margin: 0 0 4px;
}
.-type-big .p-postList__meta {
  margin: 0 0 1em;
  font-size: 12px;
}
.-type-thumb .p-postList__meta,
.c-widget .p-postList__meta {
  margin-top: 0;
}
.p-postList__author,
.p-postList__cat,
.p-postList__times,
.p-postList__views {
  margin-right: 0.5em;
  padding: 4px 0;
}
.-type-simple .p-postList__author {
  padding: 0;
}
.p-postList__author:not(:first-child) {
  margin-left: auto;
}
.-w-ranking.p-postList {
  counter-reset: number 0;
}
.-w-ranking .p-postList__item {
  position: relative;
}
.-w-ranking .p-postList__item::before {
  position: absolute;
  z-index: 2;
  display: block;
  color: #fff;
  font-family: arial, helvetica, sans-serif;
  text-align: center;
  background-color: #3c3c3c;
  content: counter(number);
  counter-increment: number;
  pointer-events: none;
}
.-w-ranking .p-postList__item:nth-child(1)::before {
  background-color: #f7d305;
  background-image: linear-gradient(
    to top left,
    #f7bf05,
    #fcea7f,
    #fcd700,
    #f7d305,
    #eaca12,
    #ac9303,
    #f7d305,
    #fcea7f,
    #f7e705,
    #eaca12,
    #f7bf05
  );
}
.-w-ranking .p-postList__item:nth-child(2)::before {
  background-color: #a5a5a5;
  background-image: linear-gradient(
    to top left,
    #a5a5a5,
    #e5e5e5,
    #ae9c9c,
    #a5a5a5,
    #a5a5a5,
    #7f7f7f,
    #a5a5a5,
    #e5e5e5,
    #a5a5a5,
    #a5a5a5,
    #a5a5a5
  );
}
.-w-ranking .p-postList__item:nth-child(3)::before {
  background-color: #c47222;
  background-image: linear-gradient(
    to top left,
    #c46522,
    #e8b27d,
    #d07217,
    #c47222,
    #b9722e,
    #834c17,
    #c47222,
    #e8b27d,
    #c48022,
    #b9722e,
    #c46522
  );
}
.-w-ranking.-type-card .p-postList__item::before {
  top: -4px;
  left: calc(0.5em + 8px);
  width: 2em;
  font-size: 3.2vw;
  line-height: 2.5;
}
.-w-ranking.-type-list .p-postList__item::before {
  top: 0;
  left: 0;
  width: 2em;
  font-size: 3vw;
  line-height: 2;
}
.-type-big .noimg_ {
  display: none;
}
.post_content .p-postList {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.post_content .-type-simple,
.post_content .-type-thumb {
  margin-bottom: 2em;
}
.post_content .p-postList__item::before {
  content: none;
}
.post_content .p-postList__title {
  display: block;
  padding: 0;
  color: inherit;
  background: 0 0;
  border: none;
}
.post_content .p-postList__title::after,
.post_content .p-postList__title::before {
  content: none;
}
.p-postListWrap {
  position: relative;
}
.post_content .p-postListWrap {
  margin-bottom: 6em;
}
.p-spMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  transition: opacity 0s;
}
[data-loaded="false"] .p-spMenu {
  display: none;
}
[data-spmenu="closed"] .p-spMenu {
  opacity: 0;
  transition-delay: 1s;
  pointer-events: none;
}
.p-spMenu a {
  color: inherit;
}
.p-spMenu .c-widget__title {
  margin-bottom: 1.5em;
}
.c-spnav {
  display: block;
  text-align: left;
}
.c-spnav .menu-item {
  position: relative;
}
.c-spnav > .menu-item:first-child > a {
  border-top: none;
}
.c-spnav a {
  display: block;
  padding: 0.75em 0.25em;
  border-bottom: solid 1px var(--color_border);
}
.c-spnav .desc {
  margin-left: 8px;
}

[data-spmenu="opened"] .p-spMenu__overlay {
  display: block;
  visibility: visible;
  background: #000;
  opacity: 0.6;
}

[data-spmenu="closed"] .p-spMenu__overlay {
  visibility: hidden;
  background: #000;
  opacity: 0.6;
}
.p-spMenu__inner {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 88vw;
  height: 100%;
  padding-top: var(--logo_size_sp);
  box-shadow: 0 0 8px var(--swl-color_shadow);
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s;
  transition-timing-function: ease-out;
}
.-left .p-spMenu__inner {
  left: 0;
  -webkit-transform: translateX(-88vw);
  transform: translateX(-88vw);
}
.-right .p-spMenu__inner {
  right: 0;
  -webkit-transform: translateX(88vw);
  transform: translateX(88vw);
}
[data-spmenu="opened"] .p-spMenu__inner {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.p-spMenu__closeBtn {
  position: absolute;
  top: 0;
  z-index: 1;
  width: var(--logo_size_sp);
  height: var(--logo_size_sp);
}
.-left .p-spMenu__closeBtn {
  left: 0;
}
.-right .p-spMenu__closeBtn {
  right: 0;
}
.p-spMenu__body {
  position: relative;
  height: 100%;
  padding: 0 4vw 10vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.p-spMenu__nav {
  margin-top: -1em;
  padding-bottom: 0.5em;
}
.p-spMenu__bottom {
  margin-top: 2em;
}
.note {
  color: #e03030;
}
.dump_data {
  display: block;
  padding: 16px;
  font-size: 13px;
  font-family: arial, sans-serif;
  white-space: pre-wrap;
  background: #fafafa;
  border: solid 1px #ddd;
}
.xdebug-var-dump {
  font-family: inherit;
  white-space: inherit;
}
.xdebug-var-dump font {
  color: #4368af;
}
.xdebug-var-dump small {
  color: #ff6868;
}
.alignwide {
  position: relative;
  width: 100%;
  max-width: initial !important;
}
h2.alignwide {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.alignfull {
  position: relative;
  left: calc(50% - 50vw);
  width: 100vw;
  max-width: 100vw !important;
}
@supports (--css: variables) {
  .alignfull {
    left: calc(50% - (50vw - var(--swl-scrollbar_width, 0) / 2));
    width: calc(100vw - var(--swl-scrollbar_width, 0));
  }
}
.alignfull > .wp-block-cover__inner-container,
.alignfull > .wp-block-group__inner-container {
  max-width: var(--article_size);
  margin: 0 auto;
}
.u-bg-main {
  color: #fff !important;
  background-color: var(--color_main) !important;
}
.cf::after,
.cf::before {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  content: "";
}
.clear,
.u-cf {
  clear: both !important;
}
.none,
.u-none {
  display: none !important;
}
.u-block {
  display: block !important;
}
.u-flex--aic {
  display: flex;
  align-items: center;
}
.fs_xs,
.has-xs-font-size,
.u-fz-xs {
  font-size: 0.7em !important;
}
.fs_s,
.has-s-font-size,
.has-small-font-size,
.u-fz-s {
  font-size: 0.9em !important;
}
.has-normal-font-size,
.has-regular-font-size {
  font-size: 1em !important;
}
.has-medium-font-size,
.u-fz-m {
  font-size: 1.1em !important;
}
.fs_l,
.has-l-font-size,
.has-large-font-size,
.u-fz-l {
  font-size: 1.2em !important;
}
.fs_xl,
.has-huge-font-size,
.has-xl-font-size,
.u-fz-xl {
  font-size: 1.6em !important;
}
.u-fz-10 {
  font-size: 10px;
}
.u-fz-11 {
  font-size: 11px;
}
.u-fz-12 {
  font-size: 12px;
}
.u-fz-13 {
  font-size: 13px;
}
.u-fz-14 {
  font-size: 14px;
}
.u-fz-15 {
  font-size: 15px;
}
.u-fz-16 {
  font-size: 16px;
}
.u-fz-18 {
  font-size: 18px;
}
.u-fw-bold {
  font-weight: 700 !important;
}
.u-fw-normal {
  font-weight: 400 !important;
}
.u-fw-lighter {
  font-weight: lighter !important;
}
.u-fs-italic {
  font-style: italic !important;
}
.u-col-main {
  color: var(--color_main) !important;
}
.u-thin {
  opacity: 0.8;
}
.hov-opacity {
  transition: opacity 0.25s;
}
.hov-opacity:hover {
  opacity: 0.8;
}
.hov-flash:hover {
  -webkit-animation: flash 1s;
  animation: flash 1s;
}
.hov-flash-up {
  transition: box-shadow 0.25s;
}
.hov-flash-up:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  -webkit-animation: flash 1s;
  animation: flash 1s;
}
.hov-col-main {
  transition: color 0.25s;
}
.hov-col-main:hover {
  color: var(--color_main) !important;
}
.u-lh-10 {
  line-height: 1 !important;
}
.u-lh-15 {
  line-height: 1.5 !important;
}
.u-lh-20 {
  line-height: 2 !important;
}
.luminous {
  cursor: -webkit-zoom-in !important;
  cursor: zoom-in !important;
}
.u-mb-ctrl + * {
  margin-top: 0 !important;
}
.u-mb-0 {
  margin-bottom: 0 !important;
}
.u-mb-5 {
  margin-bottom: 0.5em !important;
}
.u-mb-10 {
  margin-bottom: 1em !important;
}
.u-mb-15 {
  margin-bottom: 1.5em !important;
}
.u-mb-20 {
  margin-bottom: 2em !important;
}
.u-mb-25 {
  margin-bottom: 2.5em !important;
}
.u-mb-30 {
  margin-bottom: 3em !important;
}
.u-mb-40 {
  margin-bottom: 4em !important;
}
.u-mb-50 {
  margin-bottom: 5em !important;
}
.u-mb-60 {
  margin-bottom: 6em !important;
}
.u-mb-80 {
  margin-bottom: 8em !important;
}
.u-mt-0 {
  margin-top: 0 !important;
}
.u-mt-5 {
  margin-top: 0.5em !important;
}
.u-mt-10 {
  margin-top: 1em !important;
}
.u-mt-15 {
  margin-top: 1.5em !important;
}
.u-mt-20 {
  margin-top: 2em !important;
}
.u-mt-25 {
  margin-top: 2.5em !important;
}
.u-mt-30 {
  margin-top: 3em !important;
}
.u-mt-40 {
  margin-top: 4em !important;
}
.u-mt-50 {
  margin-top: 5em !important;
}
.u-mt-60 {
  margin-top: 6em !important;
}
.u-mt-80 {
  margin-top: 8em !important;
}
.u-obf-cover {
  display: block;
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
}
.al_c,
.u-ta-c {
  text-align: center;
}
.al_l,
.u-ta-l {
  text-align: left;
}
.al_r,
.u-ta-r {
  text-align: right;
}
.u-nowrap {
  white-space: nowrap;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.c-prBanner__link {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  transition: opacity 0.25s;
}
.c-prBanner__link:hover {
  opacity: 0.8;
}
.c-prBanner__img {
  width: 100%;
}
.widget_swell_ad_widget {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.widget_swell_ad_widget iframe,
.widget_swell_ad_widget ins {
  box-sizing: border-box;
  max-width: 100%;
}
.w-beforeToc {
  margin-top: 4em;
  margin-bottom: 4em;
}
.w-fixSide {
  top: calc(8px + var(--swl-adminbarH, 0px) + var(--swl-fix_headerH, 0px));
  max-height: calc(100vh - 16px - var(--swl-adminbarH, 0px) - var(--swl-fix_headerH, 0px));
  margin-top: 2em;
}
.w-frontTop {
  margin-bottom: 3em;
}
.w-frontBottom {
  margin-top: 3em;
}
.c-widget + .c-widget {
  margin-top: 2em;
}
.c-widget p:empty::before {
  content: none;
}
.c-widget .textwidget > :not(:last-child) {
  margin-bottom: 1em;
}
.c-widget__title {
  position: relative;
  margin-bottom: 1.5em;
  overflow: hidden;
  font-weight: 700;
  font-size: 4vw;
}
.c-widget__title::after,
.c-widget__title::before {
  position: absolute;
  z-index: 0;
  display: block;
  box-sizing: inherit;
  height: 1px;
}
.widget_search .c-widget__title {
  display: none;
}
.recentcomments {
  padding: 8px;
}
.cat-post-count,
.post_count,
.tag-link-count {
  position: relative;
  right: -1px;
  color: #666;
  font-size: 3vw;
}
.cat-post-count {
  margin-left: 4px;
}
.wp-block-archives-list,
.wp-block-categories-list,
.wp-block-latest-comments,
.wp-block-latest-posts,
.wp-block-rss {
  padding: 0 !important;
  list-style: none !important;
}
.has-border.-border01 {
  border: var(--border01);
}
.has-border.-border02 {
  border: var(--border02);
}
.has-border.-border03 {
  border: var(--border03);
}
.has-border.-border04 {
  border: var(--border04);
}
.has-border,
.is-style-bg_gray,
.is-style-bg_grid,
.is-style-bg_main,
.is-style-bg_main_thin,
.is-style-bg_stripe,
.is-style-border_dg,
.is-style-border_dm,
.is-style-border_sg,
.is-style-border_sm,
.is-style-dent_box,
.is-style-emboss_box,
.is-style-note_box,
.is-style-stitch,
div.has-background,
p.has-background {
  padding: 1.5em;
}
.is-style-border_sg {
  border: solid 1px rgba(200, 200, 200, 0.5);
}
.is-style-border_dg {
  border: dashed 1px rgba(200, 200, 200, 0.5);
}
.is-style-border_sm {
  border: solid 1px var(--color_main);
}
.is-style-border_dm {
  border: dashed 1px var(--color_main);
}
.is-style-bg_main {
  color: #fff;
  background-color: var(--color_main);
}
.is-style-bg_main_thin {
  background-color: var(--color_main_thin);
}
.is-style-bg_gray {
  background: var(--color_gray);
}
.is-style-balloon_box2,
.is-style-emboss_box,
.is-style-sticky_box {
  color: #333;
  background-color: #fff;
}
.is-style-bg_stripe {
  position: relative;
  background: linear-gradient(
    -45deg,
    transparent 25%,
    var(--color_gray) 25%,
    var(--color_gray) 50%,
    transparent 50%,
    transparent 75%,
    var(--color_gray) 75%,
    var(--color_gray)
  );
  background-size: 6px 6px;
  background-clip: padding-box;
}
.is-style-crease {
  position: relative;
  padding: calc(0.5em + 20px);
  padding-right: calc(0.5em + 20px);
  background-color: #f7f7f7;
}
.is-style-crease::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  background-color: inherit;
  border-color: var(--color_content_bg) var(--color_content_bg) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0 20px 20px 0;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
  content: "";
}
.is-style-bg_grid {
  background-image: linear-gradient(
      transparent calc(100% - 1px),
      var(--color_gray) 50%,
      var(--color_gray)
    ),
    linear-gradient(90deg, transparent calc(100% - 1px), var(--color_gray) 50%, var(--color_gray));
  background-repeat: repeat;
  background-size: 16px 16px;
}
.is-style-note_box,
.is-style-stitch {
  position: relative;
  display: block;
  color: #5f5a59;
  font-style: normal;
  background: #f7f7f7;
}
.is-style-note_box::before,
.is-style-stitch::before {
  position: absolute;
  top: 6px;
  left: 6px;
  display: block;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border: 1px dashed rgba(200, 200, 200, 0.6);
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
.is-style-dent_box {
  background: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.is-style-emboss_box {
  border-top: solid 2px var(--color_main);
  box-shadow: var(--swl-box_shadow);
}
.is-style-kakko_box {
  position: relative;
  padding: 2em;
}
.is-style-kakko_box::before {
  top: 0;
  left: 0;
  border-top: solid 1px;
  border-left: solid 1px;
}
.is-style-kakko_box::after {
  right: 0;
  bottom: 0;
  border-right: solid 1px;
  border-bottom: solid 1px;
}
.is-style-kakko_box::after,
.is-style-kakko_box::before {
  position: absolute;
  display: inline-block;
  width: 2.25em;
  height: 2em;
  border-color: var(--color_main);
  content: "";
}
.is-style-big_kakko_box {
  position: relative;
  padding: 2em;
}
.is-style-big_kakko_box::before {
  bottom: 0;
  left: 0;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-left: solid 1px;
}
.is-style-big_kakko_box::after {
  right: 0;
  bottom: 0;
  border-top: solid 1px;
  border-right: solid 1px;
  border-bottom: solid 1px;
}
.is-style-big_kakko_box::after,
.is-style-big_kakko_box::before {
  position: absolute;
  display: inline-block;
  width: 0.75em;
  height: 100%;
  border-color: var(--color_main);
  content: "";
}
.is-style-border_left {
  padding-left: 10px;
  border-left: solid 2px currentColor;
}
.is-style-sticky_box {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 6em;
  padding: 0.75em 1em;
  line-height: 1.4;
  border-left: solid 8px var(--color_main);
  box-shadow: var(--swl-box_shadow);
}
.is-style-balloon_box {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 6em;
  padding: 0.5em 1.25em;
  color: #fff;
  line-height: 1.4;
  background-color: var(--color_main);
  border-radius: 2px;
}
.is-style-balloon_box::before {
  position: absolute;
  bottom: -22px;
  left: 1.25em;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-top-color: var(--color_main);
  visibility: visible;
  content: "";
}
.is-style-balloon_box2 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 6em;
  padding: 0.5em 1.25em;
  line-height: 1.4;
  border: solid 1px;
  border-radius: 2px;
}
.is-style-balloon_box2::before {
  position: absolute;
  bottom: -7px;
  left: 1.25em;
  width: 12px;
  height: 12px;
  background: inherit;
  border-right: 1px solid;
  border-bottom: 1px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  visibility: visible;
  content: "";
}
.is-style-balloon_box.has-text-align-center,
.is-style-balloon_box2.has-text-align-center {
  margin-right: auto;
  margin-left: auto;
}
.is-style-balloon_box.has-text-align-center::before {
  left: calc(50% - 12px);
}
.is-style-balloon_box2.has-text-align-center::before {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}
.is-style-icon_announce,
.is-style-icon_bad,
.is-style-icon_book,
.is-style-icon_good,
.is-style-icon_info,
.is-style-icon_pen {
  position: relative;
  padding: 1em 1em 1em 4.25em;
  line-height: 1.6;
  border-radius: 2px;
}
.is-style-icon_announce::before,
.is-style-icon_bad::before,
.is-style-icon_book::before,
.is-style-icon_good::before,
.is-style-icon_info::before,
.is-style-icon_pen::before {
  position: absolute;
  top: 50%;
  left: 1.25em;
  display: inline-block;
  font-size: inherit;
  font-family: icomoon;
  -webkit-transform: translateY(-50%) scale(1.5);
  transform: translateY(-50%) scale(1.5);
  content: "";
}
.is-style-icon_announce::after,
.is-style-icon_bad::after,
.is-style-icon_book::after,
.is-style-icon_good::after,
.is-style-icon_info::after,
.is-style-icon_pen::after {
  position: absolute;
  top: 25%;
  left: 3.25em;
  display: block;
  width: 0;
  height: 50%;
  border-right: 2px dashed;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
  opacity: 0.5;
  content: "";
}
.is-style-icon_good {
  background: var(--color_icon_good_bg);
  border-color: var(--color_icon_good);
}
.is-style-icon_good::before {
  color: var(--color_icon_good);
  content: "\e8dc";
}
.is-style-icon_good::after {
  border-right-color: var(--color_icon_good);
}
.is-style-icon_bad {
  background: var(--color_icon_bad_bg);
  border-color: var(--color_icon_bad);
}
.is-style-icon_bad::before {
  color: var(--color_icon_bad);
  content: "\e8db";
}
.is-style-icon_bad::after {
  border-right-color: var(--color_icon_bad);
}
.is-style-icon_info {
  background: var(--color_icon_info_bg);
  border-color: var(--color_icon_info);
}
.is-style-icon_info::before {
  color: var(--color_icon_info);
  content: "\e91d";
}
.is-style-icon_info::after {
  border-right-color: var(--color_icon_info);
}
.is-style-icon_announce {
  background: var(--color_icon_announce_bg);
  border-color: var(--color_icon_announce);
}
.is-style-icon_announce::before {
  color: var(--color_icon_announce);
  content: "\e913";
}
.is-style-icon_announce::after {
  border-right-color: var(--color_icon_announce);
}
.is-style-icon_pen {
  background: var(--color_icon_pen_bg);
  border-color: var(--color_icon_pen);
}
.is-style-icon_pen::before {
  color: var(--color_icon_pen);
  content: "\e934";
}
.is-style-icon_pen::after {
  border-right-color: var(--color_icon_pen);
}
.is-style-icon_book {
  background: var(--color_icon_book_bg);
  border-color: var(--color_icon_book);
}
.is-style-icon_book::before {
  color: var(--color_icon_book);
  content: "\e91a";
}
.is-style-icon_book::after {
  border-right-color: var(--color_icon_book);
}
[class*="is-style-big_icon_"]::before {
  position: absolute;
  top: -1px;
  left: 0.75em;
  padding: 8px;
  color: #fff;
  font-size: 1.35em;
  font-family: icomoon;
  line-height: 1;
  border: solid 2px;
  border-color: var(--color_content_bg);
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.is-style-big_icon_point {
  border-color: var(--color_icon_point);
}
.is-style-big_icon_point::before {
  background: var(--color_icon_point);
  content: "\e915";
}
.is-style-big_icon_check,
.is-style-big_icon_good {
  border-color: var(--color_icon_check);
}
.is-style-big_icon_check::before,
.is-style-big_icon_good::before {
  background: var(--color_icon_check);
}
.is-style-big_icon_bad,
.is-style-big_icon_batsu {
  border-color: var(--color_icon_batsu);
}
.is-style-big_icon_bad::before,
.is-style-big_icon_batsu::before {
  background: var(--color_icon_batsu);
}
.is-style-big_icon_hatena {
  border-color: var(--color_icon_hatena);
}
.is-style-big_icon_hatena::before {
  background: var(--color_icon_hatena);
  content: "\e917";
}
.is-style-big_icon_caution {
  border-color: var(--color_icon_caution);
}
.is-style-big_icon_caution::before {
  padding-top: 7px;
  padding-bottom: 9px;
  background: var(--color_icon_caution);
  content: "\e916";
}
.is-style-big_icon_memo {
  border-color: var(--color_icon_memo);
}
.is-style-big_icon_memo::before {
  background: var(--color_icon_memo);
  content: "\e929";
}
.is-style-browser_mac {
  position: relative;
  padding: 32px 0 0;
  overflow: hidden;
  color: #333;
  background: #fff;
  border: solid 6px #dfe1e5;
  border-top: none;
  border-radius: 8px;
  box-shadow: 0 1px 16px -2px var(--swl-color_shadow);
}
.is-style-browser_mac .wp-block-group__inner-container {
  max-height: 400px;
  padding: 1.5em;
  overflow-y: auto;
}
.is-style-browser_mac::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 32px;
  padding: 0;
  background: #dfe1e5 url(../img/style/macdot.svg) no-repeat left 8px top 50% / auto 12px;
  content: "";
}
.is-style-browser_mac.wp-block-image,
.is-style-browser_mac.wp-block-video {
  width: 100%;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-right: auto;
  margin-left: auto;
}
.is-style-browser_mac.wp-block-image img,
.is-style-browser_mac.wp-block-video video {
  display: block;
  margin: 0 auto;
  border-radius: 2px 2px 4px 4px;
  box-shadow: 0 0 0 2px #dfe1e5;
}
.is-style-browser_mac .components-resizable-box__container {
  display: block;
}
.is-style-browser_mac > figcaption {
  position: absolute;
  top: 6px;
  left: 72px;
  max-width: calc(100% - 80px);
  margin: 0 !important;
  padding: 6px 8px 6px;
  font-size: 12px;
  line-height: 1;
  background: #fff;
  border-radius: 6px 6px 0 0;
}
.is-style-browser_mac.wp-block-image.size_s img {
  max-width: 100%;
  padding: 2% 5%;
}
.is-style-browser_mac.wp-block-image.size_xs img {
  max-width: 100%;
  padding: 2% 12%;
}
.is-style-desktop {
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.is-style-desktop .wp-block-group__inner-container,
.is-style-desktop.wp-block-image img,
.is-style-desktop.wp-block-video video {
  display: block;
  margin: 0 auto;
  border: solid 8px #3f4244;
  border-bottom-width: 16px;
  border-radius: 6px;
}
.is-style-desktop .wp-block-group__inner-container {
  width: 100%;
  max-width: 800px;
  min-height: 440px;
  max-height: 440px;
  padding: 1.5em 1.5em;
  overflow-y: auto;
}
.is-style-desktop::before {
  position: relative;
  display: block;
  order: 1;
  width: 100%;
  height: 48px;
  margin-top: -4px;
  padding: 0;
  background: url(../img/style/desktop.svg) no-repeat top center/auto 48px;
  content: "";
}
.is-style-desktop > figcaption {
  order: 3;
}
.p-articleThumb__caption,
.wp-block-embed > figcaption,
.wp-block-image figcaption,
.wp-block-table figcaption,
.wp-block-video figcaption {
  display: block !important;
  margin-top: 0.75em;
  margin-bottom: 0;
  color: inherit;
  font-size: 0.8em;
  text-align: center;
  opacity: 0.8;
}
.post_content iframe {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.instagram-media-rendered,
.twitter-tweet-rendered {
  margin-right: auto !important;
  margin-left: auto !important;
}
.twitter-timeline {
  display: block !important;
}
.fb_iframe_widget {
  display: block !important;
}
.fb_iframe_widget > span {
  display: block !important;
  margin: 0 auto;
}
.mark_blue,
.mark_green,
.mark_orange,
.mark_yellow {
  display: inline;
  padding: 2px;
}
code.dir_name::before {
  margin-right: 0.25em;
  opacity: 0.8;
}
code.file_name::before {
  margin-right: 0.25em;
}
.font_col_main,
.has-swl-main-color {
  color: var(--color_main) !important;
}
.has-swl-main-thin-color {
  color: var(--color_main_thin) !important;
}
.has-swl-gray-color {
  color: var(--color_gray) !important;
}
.has-black-color {
  color: #000 !important;
}
.has-white-color {
  color: #fff !important;
}
.has-swl-pale-01-color {
  color: var(--color_pale01) !important;
}
.has-swl-pale-02-color {
  color: var(--color_pale02) !important;
}
.has-swl-pale-03-color {
  color: var(--color_pale03) !important;
}
.has-swl-pale-04-color {
  color: var(--color_pale04) !important;
}
.font_col_red,
.has-swl-deep-01-color {
  color: var(--color_deep01) !important;
}
.font_col_blue,
.has-swl-deep-02-color {
  color: var(--color_deep02) !important;
}
.font_col_green,
.has-swl-deep-03-color {
  color: var(--color_deep03) !important;
}
.has-swl-deep-04-color {
  color: var(--color_deep04) !important;
}
.swl-bg-color,
[class*="fill_col_"] {
  padding: 0.25em 0.5em;
  border-radius: 1px;
}
.fill_col_main,
.has-swl-main-background-color {
  color: #fff;
  background-color: var(--color_main);
}
.has-swl-main-thin-background-color {
  background-color: var(--color_main_thin) !important;
}
.has-swl-gray-background-color {
  background-color: var(--color_gray) !important;
}
.has-black-background-color {
  color: #fff;
  background-color: #000 !important;
}
.has-white-background-color {
  background-color: #fff !important;
}
.fill_col_red,
.has-swl-deep-01-background-color {
  color: #fff;
  background-color: var(--color_deep01) !important;
}
.fill_col_blue,
.has-swl-deep-02-background-color {
  color: #fff;
  background-color: var(--color_deep02) !important;
}
.fill_col_green,
.has-swl-deep-03-background-color {
  color: #fff;
  background-color: var(--color_deep03) !important;
}
.has-swl-deep-04-background-color {
  color: #fff;
  background-color: var(--color_deep04) !important;
}
.has-swl-pale-01-background-color {
  background-color: var(--color_pale01) !important;
}
.has-swl-pale-02-background-color {
  background-color: var(--color_pale02) !important;
}
.has-swl-pale-03-background-color {
  background-color: var(--color_pale03) !important;
}
.has-swl-pale-04-background-color {
  background-color: var(--color_pale04) !important;
}
.post_content {
  /*--20230903---*/
  /*--  max-width: 100%; -*/
  /*--  margin: 4em 0; --*/
  /*--  padding: 0 var(--swl-pad_post_content, 0);  --*/
  line-height: 1.8;
}
.post_content > * {
  /*--  20230824 add --*/
  clear: both;
  margin-bottom: 2em;
}
.post_content > a:hover img {
  opacity: 0.8;
}
.post_content .cap_box,
.post_content .wp-block-columns,
.post_content .wp-block-embed,
.post_content .wp-block-table {
  margin: 3em auto;
}
.post_content [class*="is-style-big_icon_"] {
  margin-top: 2.5em;
}
.post_content div > :first-child,
.post_content > :first-child {
  margin-top: 0 !important;
}
.post_content dd > :last-child,
.post_content div > :last-child,
.post_content > :last-child {
  margin-bottom: 0 !important;
}
.post_content h1 {
  font-size: 2em;
}
.post_content h2 {
  position: relative;
  z-index: 1;
  // margin: 4em 0 2em;
  font-size: 1.2em;
  line-height: 1.4;
}
.post_content > h2 {
  margin-right: var(--swl-h2-margin--x, 0);
  margin-left: var(--swl-h2-margin--x, 0);
  margin-bottom: 1.5em;
}
// .post_content h3 {
//   position: relative;
//   margin: 3em 0 2em;
//   font-weight: 700;
//   font-size: 1.1em;
//   line-height: 1.4;
// }
// .post_content h3::before {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   z-index: 0;
//   display: block;
// }
.post_content h4 {
  position: relative;
  margin: 3em 0 1.5em;
  font-size: 1.05em;
  line-height: 1.4;
}
.post_content dt,
.post_content h2,
.post_content h3,
.post_content h4 {
  font-weight: 700;
}
.post_content .is-style-section_ttl {
  position: relative;
  padding: 0;
  color: inherit;
  letter-spacing: 0.2px;
  text-align: center;
  background: 0 0;
  border: none;
  border-radius: 0;
}
.post_content .is-style-section_ttl::after,
.post_content .is-style-section_ttl::before {
  border: none;
  content: none;
}
.post_content .is-style-section_ttl.has-text-align-left {
  text-align: left;
}
.post_content .is-style-section_ttl.has-text-align-right {
  text-align: right;
}
.post_content .is-style-section_ttl small {
  display: block;
  padding-top: 0.25em;
  font-size: 0.6em;
}
.post_content hr {
  margin: 2.5em 0;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.post_content blockquote {
  position: relative;
  background: var(--color_gray);
}
.post_content blockquote.is-style-large {
  padding: 1.5em 3em;
}
.post_content blockquote.is-style-large cite {
  text-align: inherit;
}
.post_content blockquote cite {
  display: block;
  margin-top: 0.75em;
  font-size: 0.8em;
  opacity: 0.8;
}
.post_content blockquote p {
  position: relative;
  z-index: 1;
  margin: 0.5em 0;
}
.post_content blockquote::after,
.post_content blockquote::before {
  position: absolute;
  z-index: 0;
  line-height: 1;
}
.post_content pre {
  padding: 0.5em 0;
  overflow-x: auto;
}
.post_content table {
  width: 100%;
  max-width: 100%;
  line-height: 1.5;
  text-align: left;
  border: none;
  border-spacing: 0;
}
.post_content table caption {
  padding: 0.5em;
  font-size: 0.9em;
  opacity: 0.8;
}
.post_content td,
.post_content th {
  min-width: var(--swl-minwidth-cell, 0);
  padding: 0.5em 0.75em;
  vertical-align: top;
  background-clip: padding-box;
  border-top: solid 1px #ededed;
  border-left: solid 1px #ededed;
}
.post_content td:last-child,
.post_content th:last-child {
  border-right: solid 1px #ededed;
}
.post_content tr:last-child > td {
  border-bottom: solid 1px #ededed;
}
.post_content thead td,
.post_content thead th {
  color: #fff;
  background-color: var(--color_main);
}
.post_content .td_to_th_ tbody tr > :first-child,
.post_content tbody th {
  position: relative;
  font-weight: 700;
}
.post_content .td_to_th_ tbody tr > :first-child::before,
.post_content tbody th::before {
  background-color: var(--swl-color_hov_gray);
  pointer-events: none;
}
.post_content .min_width10_ {
  --swl-minwidth-cell: 80px;
}
.post_content .min_width20_ {
  --swl-minwidth-cell: 160px;
}
.post_content .min_width30_ {
  --swl-minwidth-cell: 240px;
}
.post_content :not(pre) > code {
  display: inline-block;
  margin: 0 0.5em;
  padding: 0.25em 0.5em;
  color: #333;
  font-size: 0.9em;
  font-family: Menlo, Consolas, "メイリオ", sans-serif;
  line-height: 1;
  letter-spacing: 0;
  background: #f7f7f7;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.post_content small {
  font-size: 0.8em;
  opacity: 0.8;
}
.post_content ul {
  list-style: disc;
}
.post_content ol {
  list-style: decimal;
}
.post_content ol,
.post_content ul {
  padding-left: 1.5em;
}
.post_content ol.has-background,
.post_content ul.has-background {
  padding: 1.5em 1.5em 1.5em 3em;
}
.post_content ol ul,
.post_content ul ul {
  padding-left: 1.5em;
  list-style: circle;
}
.post_content ol ol,
.post_content ul ol {
  padding-left: 1.5em;
}
.post_content li {
  position: relative;
  /*--↓↓↓ margin: 0.25em 0;-- 230731 */
  margin: 0 0.5em;
  line-height: 1.5;
}
/*--↓↓↓ 20230809*/
#page-category-faq-index .post_content .docs li {
  border-bottom: 1px dotted #000;
  padding: 10px 0;
}
#page-category-faq-index .post_content .docs li a {
  text-decoration: none;
  line-height: 1.6;
}
#page-category-faq-index .post_content .docs li a:hover {
  text-decoration: underline;
}
/*--↑↑↑ 20230809*/
.post_content li ol,
.post_content li ul {
  margin: 0;
}
#sidebar .post_content {
  margin: 0;
  padding: 0;
}
.post_content.p-blogParts {
  margin: 0 0 2em;
  padding: 0;
}
.post_content.p-blogParts:first-child {
  margin-top: 0;
}
.post_content.p-blogParts:last-child {
  margin-bottom: 0;
}
.c-tabBody__item > *,
.cap_box_content > *,
.faq_a > *,
.swell-block-accordion__body > *,
.swell-block-dl__dd > *,
.swell-block-step__body > *,
.swl-has-mb--s > *,
.wp-block-column > *,
.wp-block-cover__inner-container > *,
.wp-block-group__inner-container > *,
.wp-block-media-text__content > *,
div[class*="is-style-"] > * {
  margin-bottom: 1em;
}
.alignfull > .wp-block-cover__inner-container > *,
.alignfull > .wp-block-group__inner-container > *,
.swell-block-fullWide__inner > * {
  margin-bottom: 2em;
}
.swell-block-accordion__title {
  cursor: pointer;
}
.wp-block-cover {
  overflow: hidden;
}
.wp-block-image {
  text-align: center;
}
.wp-block-image img {
  vertical-align: bottom;
}
.wp-block-image.is-resized {
  display: block;
  width: 100%;
}
[data-align] > .wp-block-image.is-resized {
  width: auto;
}
.wp-block-image figure {
  display: block !important;
}
.wp-block-image figcaption a {
  display: inline;
}
.wp-block-image::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: "";
}
.wp-block-image a {
  display: block;
  transition: opacity 0.25s;
}
.wp-block-image a:hover {
  opacity: 0.8;
}
.wp-caption {
  width: 100%;
  max-width: 100%;
  text-align: center;
}
.wp-caption-text {
  margin-top: 1em;
  font-size: 0.8em;
  opacity: 0.8;
}
img.aligncenter {
  display: block;
  clear: both;
  margin-right: auto;
  margin-left: auto;
}
.wp-block-image.is-style-border img,
.wp-block-video.is-style-border video,
img.border {
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.wp-block-image.is-style-photo_frame img,
.wp-block-video.is-style-photo_frame video,
img.photo_frame {
  border: solid 4px #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.size_s img,
img.size_s {
  display: block;
  max-width: 92%;
  margin: auto;
}
.size_xs img,
img.size_xs {
  display: block;
  max-width: 72%;
  margin: auto;
}
.is-style-num_circle ul li::before,
ol.is-style-index ul li::before,
ul.is-style-index li li::before {
  position: absolute;
  top: 0;
  left: 0.25em;
  width: 6px;
  height: calc(1em - 2px);
  color: inherit;
  background: 0 0;
  border-bottom: solid 2px;
  border-left: solid 2px;
  border-radius: 0;
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0.75;
  content: "" !important;
}
.post_content .is-style-bad_list,
.post_content .is-style-check_list,
.post_content .is-style-good_list,
.post_content .is-style-index,
.post_content .is-style-note_list,
.post_content .is-style-num_circle {
  padding-left: 0.5em;
  list-style: none;
}
ol:not(.is-style-default)[class*="is-style-"].has-background,
ul:not(.is-style-default)[class*="is-style-"].has-background {
  padding: 1.5em;
}
ol:not(.is-style-default)[class*="is-style-"] ol,
ol:not(.is-style-default)[class*="is-style-"] ul,
ul:not(.is-style-default)[class*="is-style-"] ol,
ul:not(.is-style-default)[class*="is-style-"] ul {
  padding-left: 0;
  list-style: none;
}
ol:not(.is-style-default)[class*="is-style-"] {
  counter-reset: li;
}
ol:not(.is-style-default)[class*="is-style-"] > li::before {
  counter-increment: li;
}
ol:not(.is-style-default)[class*="is-style-"][reversed] > li::before {
  counter-increment: li -1;
}
ul.is-style-index li {
  position: relative;
  padding-left: 1.25em;
}
ul.is-style-index li::before {
  position: absolute;
  top: calc(0.75em - 3px);
  left: 2px;
  display: block;
  width: 6px;
  height: 6px;
  color: inherit;
  background-color: currentColor;
  border-radius: 50%;
  content: "";
}
ol.is-style-index {
  list-style-type: none;
}
ol.is-style-index ol > li,
ol.is-style-index > li {
  position: relative;
  margin-left: 2em;
  padding-left: 0;
}
ol.is-style-index ol > li::before,
ol.is-style-index > li::before {
  position: absolute;
  top: 0.25em;
  right: calc(100% + 0.5em);
  display: block;
  width: auto;
  padding: 0 0.5em 0 0;
  font-family: inherit;
  line-height: 1.1;
  letter-spacing: -0.1px;
  white-space: nowrap;
  border-right: solid 1px currentColor;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  content: counter(li);
  counter-increment: li;
}
ol.is-style-index ol {
  padding-left: 1em;
  counter-reset: li2;
}
ol.is-style-index ol > li::before {
  opacity: 0.75;
  content: counter(li) "-" counter(li2);
  counter-increment: li2;
}
ol.is-style-index ol ol {
  counter-reset: li3;
}
ol.is-style-index ol ol > li::before {
  opacity: 0.75;
  content: counter(li) "-" counter(li2) "-" counter(li3);
  counter-increment: li3;
}
ol.is-style-index ul li {
  margin-left: 0;
  padding-left: 1.25em;
}
ol.is-style-index ul li::before {
  counter-increment: none;
}
.is-style-bad_list li,
.is-style-check_list li,
.is-style-good_list li {
  padding-left: 1.75em;
}
.is-style-bad_list li::before,
.is-style-check_list li::before,
.is-style-good_list li::before {
  position: absolute;
  top: 0;
  left: 0.25em;
  display: block;
  width: 1em;
  height: auto;
  font-family: icomoon;
  background: 0 0;
  border: none;
  border-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.is-style-bad_list li li::before,
.is-style-check_list li li::before,
.is-style-good_list li li::before {
  opacity: 0.8;
}
.is-style-check_list li::before {
  color: var(--color_list_check);
}
.is-style-good_list li::before {
  color: var(--color_list_good);
}
.is-style-bad_list li::before {
  color: var(--color_list_bad);
}
.is-style-note_list {
  font-size: 0.9em;
}
.is-style-note_list li {
  margin: 0.5em 0;
  padding-left: 1.5em;
  opacity: 0.8;
}
.is-style-note_list li::before {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  white-space: nowrap;
  background: 0 0;
  border: none;
  border-radius: 0;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  content: "※";
}
ol.is-style-note_list li {
  padding-left: 1.75em;
}
ol.is-style-note_list li::before {
  letter-spacing: 1px;
  content: "※" counter(li);
  counter-increment: li;
}
.is-style-num_circle li {
  padding-left: 2em;
}
.is-style-num_circle li::before {
  position: absolute;
  top: -0.25em;
  left: 1px;
  display: block;
  box-sizing: content-box;
  width: 2em;
  height: 2em;
  padding: 0;
  color: #fff;
  line-height: 2;
  text-align: center;
  background-color: var(--color_list_num);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--color_list_num);
  -webkit-transform: scale(0.68);
  transform: scale(0.68);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  content: counter(li);
}
.is-style-num_circle ol {
  counter-reset: li2;
}
.is-style-num_circle ol li::before {
  color: var(--color_list_num);
  background: 0 0;
  box-shadow: 0 0 0 1px var(--color_list_num);
  opacity: 0.75;
}
.is-style-num_circle ol > li::before {
  content: counter(li2);
  counter-increment: li2;
}
.is-style-num_circle ol ol {
  counter-reset: li3;
}
.is-style-num_circle ol ol > li::before {
  content: counter(li3);
  counter-increment: li3;
}
.is-style-num_circle ul li {
  padding-left: 1.25em;
}
.-list-under-dashed > li,
.border_bottom > li {
  margin-bottom: 0.75em;
  padding-bottom: 0.75em;
  border-bottom: dashed 1px rgba(200, 200, 200, 0.5);
}
ol.-list-flex,
ul.-list-flex {
  display: flex;
  flex-wrap: wrap;
}
.-list-flex > li {
  margin-right: 1em;
}
.has-large-font-size {
  font-size: 1.4em;
}
.wp-block-video {
  text-align: center;
}
.swell-block-button[data-align="right"] {
  text-align: right;
}
.swell-block-button[data-align="left"] {
  text-align: left;
}
.swell-block-button a {
  text-align: center;
}
.swell-block-button .swell-block-button__link[data-has-icon] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.swell-block-button .__icon {
  margin-right: 0.5em;
}
.swell-block-button svg {
  width: 1.1em;
  height: 1.1em;
}
.swell-block-button__img {
  display: none !important;
  border: none;
}
[class*="is-style-btn_"] {
  position: relative;
  display: block;
  margin: 0 auto 2em;
  text-align: center;
}
[class*="is-style-btn_"] a {
  position: relative;
  display: inline-block;
  min-width: 64%;
  margin: 0;
  padding: 0.75em 1.5em;
  line-height: 1.5;
  letter-spacing: 1px;
  text-decoration: none;
  background: var(--color_main);
}
[class*="is-style-btn_"].-size-s a {
  min-width: 40%;
  font-size: 0.9em;
}
[class*="is-style-btn_"].-size-l a {
  min-width: 100%;
  padding: 1em 1.5em;
}
[class*="is-style-btn_"] img {
  display: none;
}
.is-style-btn_normal a {
  color: #fff;
  font-weight: 700;
  transition: box-shadow 0.25s;
}
.is-style-btn_normal a:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 12px 24px -12px rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.is-style-btn_solid a {
  z-index: 1;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 4px 0 var(--color_main_dark);
  transition: box-shadow 0.25s, -webkit-transform 0.25s;
  transition: transform 0.25s, box-shadow 0.25s;
  transition: transform 0.25s, box-shadow 0.25s, -webkit-transform 0.25s;
}
.is-style-btn_solid a:hover {
  box-shadow: 0 0 0 transparent !important;
  -webkit-transform: translate3d(0, 4px, 0);
  transform: translate3d(0, 4px, 0);
}
.is-style-btn_solid.red_ a {
  box-shadow: 0 4px 0 var(--color_btn_red_dark);
}
.is-style-btn_solid.blue_ a {
  box-shadow: 0 4px 0 var(--color_btn_blue_dark);
}
.is-style-btn_solid.green_ a {
  box-shadow: 0 4px 0 var(--color_btn_green_dark);
}
.is-style-btn_shiny a {
  color: #fff;
  font-weight: 700;
}
.is-style-btn_line a {
  position: relative;
  color: var(--color_main);
  background: 0 0;
  border: solid 1px currentColor;
  transition: background-color 0.25s;
}
.is-style-btn_line a:hover {
  color: #fff !important;
  background-color: var(--color_main);
  border-color: transparent;
}
.is-style-btn_line.red_ a {
  color: var(--color_btn_red);
}
.is-style-btn_line.blue_ a {
  color: var(--color_btn_blue);
}
.is-style-btn_line.green_ a {
  color: var(--color_btn_green);
}
.is-style-more_btn {
  position: relative;
  display: block;
  margin: 0 auto 2em;
  padding: 2px 0;
  text-align: center;
}
.is-style-more_btn img {
  display: none;
}
.is-style-more_btn a {
  position: relative;
  display: inline-block;
  min-width: 80%;
  padding: 0.75em 3em;
  color: #666;
  font-size: 3.4vw;
  text-decoration: none;
  background: #fff;
  box-shadow: 0 0 0 1px #dcdcdc;
  transition: box-shadow 0.25s, background-color 0.25s;
}
.is-style-more_btn a::after {
  position: absolute;
  top: 50%;
  right: 1.25em;
  font-family: icomoon;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  content: "\e930";
}
.is-style-more_btn a:hover {
  color: #fff;
  background-color: var(--color_main);
  box-shadow: none;
}
.is-style-more_btn a:hover::after {
  -webkit-transform: translateX(4px) translateY(-50%);
  transform: translateX(4px) translateY(-50%);
}
.is-style-btn_line.red_ a:hover,
.is-style-btn_normal.red_ a,
.is-style-btn_shiny.red_ a,
.is-style-btn_solid.red_ a {
  background: var(--color_btn_red);
}
.is-style-btn_line.blue_ a:hover,
.is-style-btn_normal.blue_ a,
.is-style-btn_shiny.blue_ a,
.is-style-btn_solid.blue_ a {
  background: var(--color_btn_blue);
}
.is-style-btn_line.green_ a:hover,
.is-style-btn_normal.green_ a,
.is-style-btn_shiny.green_ a,
.is-style-btn_solid.green_ a {
  background: var(--color_btn_green);
}
.swl-inline-btn {
  display: inline-block;
  margin: 0 4px;
}
.swl-inline-btn a {
  padding: 0.5em 1em;
}
table .swl-inline-btn {
  display: block;
  margin: 0 auto;
}
.swell-block-columns .swell-block-columns__inner:last-child {
  margin-bottom: calc(-1 * var(--swl-clmn-mrgn--y)) !important;
}
.swell-block-columns .swell-block-column:last-child {
  margin-bottom: var(--swl-clmn-mrgn--y) !important;
}
[data-spmenu="opened"] body > .adsbygoogle {
  top: -140px !important;
}
@media (min-width: 600px) {
  :root {
    --swl-h2-margin--x: -16px;
  }
  .l-content {
    padding-top: 1em; /*--  20230824 add --*/
  }
  .-txt .c-headLogo__link {
    font-size: 24px;
  }
  .c-mvBtn__btn {
    font-size: 14px;
  }
  .c-postTitle__ttl {
    font-size: 24px;
  }
  .c-postTitle__date .__y {
    font-size: 14px;
  }
  .c-postTitle__date .__md {
    font-size: 24px;
  }
  .p-postList .c-postTitle__date .__md,
  .p-postList .c-postTitle__ttl {
    font-size: 20px;
  }
  .c-postThumb__cat {
    font-size: 11px;
  }
  .p-blogCard__caption {
    left: 24px;
    font-size: 12px;
  }
  .p-blogCard__inner {
    padding: 24px;
  }
  .p-blogCard__thumb {
    width: 21%;
  }
  [data-type="type1"] .p-blogCard__inner {
    padding-top: 25px;
  }
  [data-type="type2"] .p-blogCard__inner {
    padding-top: 28px;
  }
  [data-type="type3"] .p-blogCard__caption {
    right: 8px;
    bottom: 8px;
  }
  .p-breadcrumb__text {
    font-size: 11px;
  }
  .-frame-off .p-postList.-type-list2 {
    padding-top: 2em;
  }
  .-type-list2 .p-postList__item {
    margin-bottom: 2em;
    padding-bottom: 2em;
  }
  .-type-card.-pc-col1 .p-postList__item,
  .-type-thumb.-pc-col1 .p-postList__item {
    width: 100%;
  }
  .-type-card.-pc-col1 .p-postList__item {
    margin: 0 0 3em;
  }
  .-type-big .p-postList__body::after {
    width: 80%;
  }
  .-type-card .p-postList__title,
  .-type-list .p-postList__title,
  .-type-list2 .p-postList__title {
    font-size: 16px;
  }
  #sidebar .-type-card .p-postList__title,
  .-related .p-postList__title,
  .-type-thumb .p-postList__title,
  .w-footer:not(.-col1) .p-postList__title {
    font-size: 14px;
  }
  #sidebar .-type-list .p-postList__title {
    font-size: 13px;
  }
  .-type-card .p-postList__excerpt {
    font-size: 13px;
  }
  .-type-list .p-postList__excerpt,
  .-type-list2 .p-postList__excerpt {
    font-size: 14px;
  }
  .p-postList__meta {
    font-size: 11px;
  }
  .-w-ranking.-type-card .p-postList__item::before {
    font-size: 14px;
  }
  .-w-ranking.-type-list .p-postList__item::before {
    font-size: 12px;
  }
  .c-widget__title {
    font-size: 16px;
  }
  .cat-post-count,
  .post_count,
  .tag-link-count {
    font-size: 13px;
  }
  div.has-background,
  div.has-border,
  div.is-style-bg_gray,
  div.is-style-bg_grid,
  div.is-style-bg_main,
  div.is-style-bg_main_thin,
  div.is-style-bg_stripe,
  div.is-style-border_dg,
  div.is-style-border_dm,
  div.is-style-border_sg,
  div.is-style-border_sm,
  div.is-style-dent_box,
  div.is-style-emboss_box,
  div.is-style-note_box,
  div.is-style-stitch {
    padding: 1.5em; /*--20230903---*/
  }
  [class*="is-style-big_icon_"] {
    padding: 2.5em 2em 2em;
  }
  .post_content h2 {
    // margin: 4em 0 2em;
    font-size: 1.647em;
  }
  .post_content h3 {
    font-size: 1.647em;
  }
  .post_content h4 {
    font-size: 1.2em;
  }
  [class*="is-style-btn_"] a {
    min-width: 40%;
  }
  [class*="is-style-btn_"].-size-s a {
    min-width: 20%;
  }
  [class*="is-style-btn_"].-size-l a {
    min-width: 80%;
  }
  .is-style-more_btn a {
    min-width: 64%;
    font-size: 14px;
  }
}
@media (min-width: 960px) {
  :root {
    --swl-pad_post_content: 16px;
    --swl-pad_container: 48px;
  }
  .-sidebar-on .l-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-smallNavTitle {
    position: relative;
    top: 4px;
    display: block;
  }
  .c-headLogo {
    height: var(--logo_size_pc);
  }
  .l-fixHeader .c-headLogo {
    height: var(--logo_size_pcfix);
  }
  .-sidebar-on .l-mainContent {
    width: calc(100% - var(--swl-sidebar_width) - var(--swl-sidebar_margin));
  }
  #sidebar .c-widget + .c-widget {
    margin-top: 2.5em;
  }
  .-sidebar-on #sidebar {
    width: var(--swl-sidebar_width);
  }
  .c-fixBtn:hover {
    opacity: 1;
  }
  .p-fixBtnWrap {
    right: 2.5em;
    bottom: 2.5em;
  }
  #sidebar .-type-card .p-postList__item {
    width: 100%;
  }
  .-type-card.-pc-col2 .p-postList__item {
    padding: 0 0.75em;
  }
  .w-footer.-col1 .-type-card .p-postList__item {
    width: calc(100% / 3);
  }
  .w-footer.-col1 .-type-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5em;
    margin-left: -0.5em;
  }
  .w-footer.-col1 .-type-list .p-postList__item {
    width: 50%;
    padding: 0.5em;
  }
  .is-hide-last--pc .p-postList__item:last-child {
    display: none;
  }
  .p-spMenu {
    display: none;
  }
  .hov-bg-main {
    transition: background-color 0.25s, color 0.25s;
  }
  .hov-bg-main:hover {
    color: #fff !important;
    background-color: var(--color_main) !important;
  }
  .pc-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .pc-py-20 {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
  .pc-py-40 {
    padding-top: 4em !important;
    padding-bottom: 4em !important;
  }
  .pc-py-60 {
    padding-top: 6em !important;
    padding-bottom: 6em !important;
  }
  .pc-py-80 {
    padding-top: 8em !important;
    padding-bottom: 8em !important;
  }
  .sp_,
  .sp_only {
    display: none !important;
  }
  .w-fixSide {
    position: sticky;
    display: flex;
    flex-direction: column;
    margin-top: 2.5em;
  }
  .w-fixSide .c-widget {
    flex: 1 1 auto;
  }
  .w-fixSide .widget_swell_index,
  .w-fixSide .widget_swell_new_posts,
  .w-fixSide .widget_swell_popular_posts {
    overflow-y: auto !important;
  }
  .w-fixSide.-multiple .c-widget {
    max-height: 50vh;
  }
  .wp-block-cover {
    padding: 2em;
  }
  .wp-block-image.is-style-photo_frame img,
  .wp-block-video.is-style-photo_frame video,
  img.photo_frame {
    border: solid 6px #fff;
  }
}
@media (min-width: 1020px) {
  .-sidebar-off .-type-card.-pc-col3 .p-postList__item,
  .-sidebar-off .-type-thumb.-pc-col3 .p-postList__item {
    width: calc(100% / 3);
  }
}
@media (min-width: 1320px) {
  .-sidebar-on .-type-card.-pc-col3 .p-postList__item,
  .-sidebar-on .-type-thumb.-pc-col3 .p-postList__item {
    width: calc(100% / 3);
  }
}
@media (max-width: 1200px) {
  .c-gnav > .menu-item > a {
    padding: 0 10px;
  }
}
@media (max-width: 1080px) {
  .c-gnav > .menu-item > a {
    padding: 0 8px;
  }
}
@media (max-width: 959px) {
  @-webkit-keyframes leftToRight {
    0% {
      -webkit-transform: scale(1.15) translateX(-1.5%);
      transform: scale(1.15) translateX(-1.5%);
    }
    100% {
      -webkit-transform: scale(1.15) translateX(1.5%);
      transform: scale(1.15) translateX(1.5%);
    }
  }
  @keyframes leftToRight {
    0% {
      -webkit-transform: scale(1.15) translateX(-1.5%);
      transform: scale(1.15) translateX(-1.5%);
    }
    100% {
      -webkit-transform: scale(1.15) translateX(1.5%);
      transform: scale(1.15) translateX(1.5%);
    }
  }
  [data-spmenu="opened"] body {
    overflow-y: hidden;
    touch-action: none;
  }
  #wpadminbar {
    position: fixed !important;
  }
  #content {
    overflow: hidden;
  }
  html[data-has-theadfix] #content {
    overflow: visible;
  }
  .l-fixHeader {
    display: none;
  }
  .l-header__gnav {
    display: none;
  }
  .l-header[data-spfix="1"] {
    position: sticky;
    top: var(--swl-adminbarH, 0);
  }
  .l-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  .l-header__inner .c-catchphrase {
    display: none;
  }
  .l-header__logo {
    padding: 0 2vw;
  }
  .c-headLogo {
    height: var(--logo_size_sp);
  }
  .-img .c-headLogo__link {
    display: block;
    padding: 10px 0 0 0;
  }
  #sidebar {
    margin-top: 4em;
  }
  .p-blogCard__excerpt {
    display: none;
  }
  .p-fixBtnWrap {
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);
  }
  #fix_bottom_menu + .p-fixBtnWrap {
    bottom: 64px;
  }
  .is-hide-last--sp .p-postList__item:last-child {
    display: none;
  }
  .sp-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sp-py-20 {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
  .sp-py-40 {
    padding-top: 4em !important;
    padding-bottom: 4em !important;
  }
  .sp-py-60 {
    padding-top: 6em !important;
    padding-bottom: 6em !important;
  }
  .sp-py-80 {
    padding-top: 8em !important;
    padding-bottom: 8em !important;
  }
  .pc_,
  .pc_only {
    display: none !important;
  }
  .w-fixSide {
    max-height: unset !important;
  }
  .is-style-desktop .wp-block-group__inner-container {
    max-width: 640px;
    min-height: 360px;
    max-height: 360px;
  }
  .post_content .min_width10_ {
    --swl-minwidth-cell: 10vw;
  }
  .post_content .min_width20_ {
    --swl-minwidth-cell: 20vw;
  }
  .post_content .min_width30_ {
    --swl-minwidth-cell: 28vw;
  }
}
@media screen and (max-width: 782px) {
  .admin-bar {
    --swl-adminbarH: 46px;
  }
  /*--↓↓↓ 20230809*/
  #breadCrumbs {
    display: none;
  }

  #junl .junl_bottom_wrapper #keywords {
    margin-right: 0;
  }

  #junl .junl__content__btn__img__text {
    padding-left: 10px;
    padding-right: 10px;
  }

  #ulNews.piece .pieceContainer .pieceBody .content li .text-content-wrapper .title_link {
    font-size: 140%;
  }

  #ulNews.piece .pieceContainer .pieceBody .content li {
    display: block;
  }
  #ulNews.piece .pieceContainer .pieceBody .content li .image {
    width: 100%;
    margin: 0 0 10px 0;
  }
  #ulNews.piece .pieceContainer .pieceBody .content li .text-content-wrapper {
    width: 100%;
    padding-left: 0;
  }

  .post_content ul.bannerImages {
    display: block;
  }

  ul.bannerImages li {
    text-align: center;
    margin: 0 0 20px 0;
  }
  /*--↑↑↑ 20230809*/
}
@media (max-width: 599px) {
  body {
    font-size: 3.6vw;
  }
  .page-numbers[data-apart="2"]:not(:first-child):not(:last-child) {
    display: none;
  }
  .c-postThumb__cat {
    -webkit-transform: scale(0.9) perspective(0);
    transform: scale(0.9) perspective(0);
  }
  .p-blogCard__title {
    font-size: 3.6vw;
  }
  .-type-card.-sp-col1 .p-postList__item,
  .-type-thumb.-sp-col1 .p-postList__item {
    width: 100%;
  }
  .-type-list2 .p-postList__body::after {
    content: none !important;
  }
  .p-postList__title .-type-thumb.-sp-col2 {
    font-size: 3.2vw;
  }
  .-type-card.-sp-col1 .p-postList__title {
    font-size: 3.8vw;
  }
  .-type-card.-sp-col2 .c-infeedAd {
    width: 100%;
  }
  .is-style-browser_mac .wp-block-group__inner-container {
    max-height: 56vw;
  }
  .is-style-desktop .wp-block-group__inner-container {
    min-height: 56vw;
    max-height: 56vw;
  }
  .instagram-media,
  .twitter-tweet {
    display: none !important;
    box-sizing: border-box !important;
    width: 100% !important;
    min-width: 0 !important;
    max-width: 88vw !important;
  }
  .instagram-media-rendered,
  .twitter-tweet-rendered {
    display: block !important;
  }
  .twitter-tweet > iframe {
    width: 100% !important;
  }
}
@media (min-width: 960px) and (max-width: 1019px) {
  .-sidebar-off .-type-card.-pc-col3 .p-postList__item {
    padding: 0 0.75em;
  }
}
@media (min-width: 960px) and (max-width: 1319px) {
  .-sidebar-on .-type-card.-pc-col3 .p-postList__item {
    padding: 0 0.75em;
  }
}
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}

// ヘッダーお問い合わせ（営業時間＆お急ぎの場合）
.c-catchphrase__headerContactText {
  background-color: #e7f8eb;
  max-width: 506px;
  min-width: 506px;
  width: 506px;
  height: 100px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    width: fit-content;
    height: 70px;
    vertical-align: bottom;
    font-size: 18px;
    font-weight: bold;
    color: #543b1a;
    line-height: 1;
    .eigyoTime {
      font-size: 25px;
    }
    .eigyoYobi {
      font-size: 17px;
    }
    .oisogi {
      font-size: 24px;
      color: #ff0000;
      padding-right: 0.5em;
    }
    .odenwade {
      font-size: 16px;
    }
    .odebwaLogo {
      vertical-align: text-bottom;
      img {
        width: 42px;
        height: 42px;
      }
    }
    .denwa {
      font-size: 28px;
      vertical-align: bottom;
      line-height: 0.7;
      color: #543b1a;
    }
  }
}

// 見出しロゴ
.h2-img img {
  height: 1.4em;
  width: auto;
}

// グレーテキスト
.gray-text,
.gray-text2 {
  color: #707070;
}

.gray-text {
  font-size: 1.059rem;
  @media (max-width: 599px) {
    font-size: 1.2rem;
  }
}

.gray-text2 {
  font-size: 1.059rem;
  @media (max-width: 599px) {
    font-size: 1rem;
  }
}

// ジャンル
#junl {
  h3 {
    padding: 0;
    font-size: 1.647rem;
    font-weight: bold;
    @media (max-width: 599px) {
      font-size: 1.4rem;
    }
  }
  .junl__content {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 13px 12px;
    @media (max-width: 959px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7f8eb;
      border: 1px solid #401f00;
      width: 100%;
      height: 100%;
      padding: 15px; /*--  20230824 add --*/
      /*--  aspect-ratio: 129/101; 20230824--*/
      &__img {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: auto;
        padding-top: 10px;
        &__text {
          display: block;
          color: #000000;
          font-weight: bold;
          font-size: 1rem;
          padding-top: 12px; /*--  20230824 add --*/
          padding-bottom: 0; /*--  20230824 add --*/
          @media (max-width: 599px) {
            padding-top: 10px;
            padding-bottom: 5px;
          }
        }
      }
      img {
        padding-bottom: 0;
        margin: 0 auto;
        display: block;
        width: auto;
        // max-height: 7.235rem;
        // min-height: 7.235rem;
        height: 5.235rem; /*--  20230824 add --*/
        @media (max-width: 599px) {
          height: 5rem;
        }
      }
      &:hover {
        /*--  20230824 add --*/
        opacity: 0.6;
      }
    }
  }
  .junl_bottom_wrapper {
    margin-top: 2.706em;
    display: flex;
    justify-content: space-between;
    @media (max-width: 959px) {
      display: block;
    }
    #keywords {
      margin-right: 2.706em;
      flex-basis: 50%;
      h3 {
        font-size: 1.647rem;
        @media (max-width: 599px) {
          font-size: 1.4rem;
        }
      }
      p {
        color: #707070;
        font-weight: normal;
        a {
          color: #707070;
          font-size: 1.059rem;
          @media (max-width: 599px) {
            font-size: 1.2rem;
            line-height: 1.5;
          }
        }
        @media (max-width: 599px) {
          font-size: 0.7em;
        }
      }
    }
    .searchbox {
      h3 {
        font-size: 1.647rem;
        @media (max-width: 599px) {
          font-size: 1.4rem;
        }
      }
      flex-basis: 50%;
      form {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .keyword {
          label {
            display: none;
          }
          input {
            width: 100%;
          }
          width: 100%;
          height: 53px;
          margin-right: 22px;
          @media (max-width: 599px) {
            height: 40px;
          }
        }
        .submitters {
          border: none;
          width: 98px;
          height: 53px;
          text-align: center;
          font-size: 1.588em;
          font-weight: bold;
          line-height: 1;
          input {
            color: #ffffff;
            background-color: #f39801;
          }

          @media (max-width: 599px) {
            width: 80px;
            height: 40px;
          }
        }
      }
    }
  }
}

// なんでもかんでも街の便利屋さんはこんな便利屋さん
.flexbox {
  display: flex;
  @media (max-width: 959px) {
    display: block;
  }
  img {
    width: 363px;
    height: 265px;
    @media (max-width: 959px) {
      width: 100%;
      height: 100%;
    }
  }
  .txt {
    p {
      margin-left: 0.706em;
      @media (max-width: 959px) {
        margin: 0 auto;
      }
    }
  }
}

// このお悩み解決しました＆お客様の声
#problemSolving.piece,
#topVoice.piece {
  .pieceContainer {
    .pieceBody {
      ul {
        .current {
          display: none;
        }
      }
      .content {
        ul {
          padding-left: 0;
          display: flex;
          flex-wrap: nowrap;
          align-items: stretch;
          gap: 10px; /*-- 20230825--*/
          @media (max-width: 599px) {
            display: block;
          }
        }
        li {
          list-style: none;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .title_link {
            a {
              display: block;
              padding-top: 1rem;
              text-align: center;
              line-height: 1;
              font-size: 1rem; /*-- 20230825--*/
              color: #707070;
              font-weight: normal;
              @media (max-width: 599px) {
                font-size: 1.2rem;
                padding-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

#problemSolving.piece {
  // margin: 0 auto;
  .pieceContainer {
    margin: 0 auto 2em auto;
    .pieceBody {
      .content {
        li {
          .image {
            display: block;
            width: auto;
            max-height: 250px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @media (max-width: 959px) {
              margin: 0 auto;
            }
            @media (max-width: 599px) {
              max-height: none;
            }
          }
          .title_link {
            a {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

#topVoice.piece {
  .pieceContainer {
    margin: 0 auto 2em auto;
    .pieceBody {
      .content {
        li {
          .image {
            display: block;
            width: auto;
            max-height: 250px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @media (max-width: 959px) {
              margin: 0 auto;
            }
            @media (max-width: 599px) {
              max-height: none;
            }
          }
          .title_link {
            a {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

// お知らせ
#ulNews.piece {
  .pieceContainer {
    .pieceBody {
      ul {
        .news.current {
          display: none;
        }
      }
      .content {
        width: 100%;
        #news.news {
          padding: 0;
        }
        li {
          margin-bottom: 40px;
          span {
            color: #707070;
          }
          list-style: none;
          display: flex;

          .image {
            display: block;
            width: 70%; /*-- 20230825--*/
            height: auto;

            a img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text-content-wrapper {
            /*--  width: 60%;  --*/ /*-- 20230825--*/
            padding-left: 20px; /*-- 20230825--*/
            .title_link {
              display: block;
              a {
                color: #707070;
                font-weight: bold;
              }
            }
          }
          .doc_link {
            display: block;
            margin-top: 1rem;
            color: #707070;
            text-align: right;
          }
        }

        li + li {
          border-top: 1px dashed #707070;
          padding-top: 40px;
        }
      }
    }
  }
}

// お問い合わせ
.form-info-text {
  width: 90%;
  padding-top: 24px;
  margin: 0 auto 58px auto;
  @media (max-width: 599px) {
    line-height: 1.3;
  }
}

.form-info-content {
  width: 100%;
  margin: 0 auto 37px auto;
  &__logo {
    width: fit-content;
    margin: 0 auto;
  }
  &__title {
    /*--↓↓↓ font-size: 1.25rem-- 230731 */
    font-size: 1.6rem !important;
    width: fit-content;
    margin: 0 auto;
    @media (max-width: 959px) {
      font-size: 1.5rem !important;
    }
    @media (max-width: 599px) {
      font-size: 1rem !important;
    }
  }
  .logo-tel {
    width: 72px;
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo-mail {
    width: 72px;
    height: auto;
  }
  .logo-form {
    width: 72px;
    height: auto;
  }
  &__info {
    a {
      display: block;
      width: fit-content;
      margin: 0 auto;
      font-size: 1.3rem;
      font-weight: bold;
      color: #e44141;
      @media (max-width: 959px) {
        font-size: 2rem;
      }
      @media (max-width: 599px) {
        font-size: 1rem;
      }
    }

    &__annotation {
      width: fit-content;
      margin: 0 auto;
      font-size: 1.2rem;
      @media (max-width: 959px) {
        font-size: 2rem;
      }
      @media (max-width: 599px) {
        font-size: 1rem;
      }
    }
  }
  &__formBtn {
    width: 80%;
    margin: 34px auto 15px auto; /*--20230903---*/
    background-color: #f39801;
    padding-top: 1.647rem;
    padding-bottom: 1.647rem;
    /*--↓↓↓   font-size: 2.294rem;-- 230731 */
    font-size: 1.8rem;

    @media (max-width: 959px) {
      width: 75%;
      font-size: 1.5rem;
    }
    @media (max-width: 599px) {
      width: 80%;
      font-size: 0.8em;
      padding-top: 0.824rem;
      padding-bottom: 0.824rem;
    }

    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 12px 24px -12px rgba(0, 0, 0, 0.2);
      /*--↓↓↓   opacity: 1;-- 230731 */
      opacity: 0.8;
    }
    a {
      color: #ffffff;
      display: block;
      width: fit-content;
      margin: 0 auto;
      font-weight: 700;
    }
    .form-triangle {
      /*--↓↓↓   font-size: 0.8em;-- 230731 */
      font-size: 0.4em;
      padding-right: 0.5rem;
      vertical-align: middle;
    }
  }
}

/*---
.bannerImages
---*/

.post_content ul.bannerImages {
  display: flex;
  margin: 0;
  padding: 0;
}

ul.bannerImages li {
  list-style: none;
}

ul.bannerImages li a {
  /*--  20230824 add --*/
  border: 1px solid #dedede;
  display: block;
  padding: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: arial, sans-serif;
  font-size: 16px;
  color: #222;
  background-color: #fff;
}

ol,
ul,
dl {
  margin-bottom: 16px;
  padding-left: 32px;
}

/* CKEditor templates
----------------------------------------------- */
.temp1,
.temp2,
.temp3,
.temp4 {
  clear: both;
  margin-bottom: 20px;
}

.temp1 .thumb {
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  width: 350px; /*--20230829--*/
}

.temp1 ul,
.temp1 ol {
  display: inline-block;
  vertical-align: top;
}

.temp2 .thumb {
  float: right;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 350px; /*--20230829--*/
}

.temp3,
.temp4 {
  display: flex;
  justify-content: center;
}

.temp3 dl,
.temp4 dl {
  margin: 0 5px;
  padding: 0;
  vertical-align: bottom;
}

.temp3 dl {
  max-width: 48%;
}

.temp4 dl {
  max-width: 31%;
}

.temp3 dl dt,
.temp4 dl dt {
  margin-bottom: 10px;
  text-align: center;
}

.temp3 dl dd,
.temp4 dl dd {
  margin: 0;
  text-align: center;
}

table.temp5,
table.temp6 {
  margin-bottom: 30px;
}

.temp5 caption,
.temp6 caption {
  text-align: center;
}

.temp5 th,
.temp5 td,
.temp6 th,
.temp6 td {
  padding: 6px 10px;
  border: 1px solid #b3b3b3;
  text-align: center;
}

.adobeReader,
.temp7 {
  clear: both;
  margin: 0 0 20px;
  padding: 10px 20px 10px 196px;
  border: 1px solid #faa;
  background: #fff3f3 url("/_themes/images/adobe_bn.png") no-repeat 20px center;
}

.adobeReader p,
.temp7 p {
  margin: 0 0 10px;
}

@media only screen and (max-width: 959px) {
  .temp1 .thumb,
  .temp2 .thumb {
    float: none;
    margin: 0 0 1rem;
    text-align: center;
  }

  .temp3,
  .temp4 {
    flex-wrap: wrap;
  }

  .temp3 dl,
  .temp4 dl {
    max-width: 100%;
  }

  .adobeReader,
  .temp7 {
    padding: 55px 20px 10px;
    background-position: center 10px;
  }
}

/* clearfix
----------------------------------------------- */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* SPMenu
----------------------------------------------- */
.p-spMenu__nav {
  li {
    i {
      padding-right: 5px;
    }
  }
}

/*--20230909--*/
/* =========================================================
 page-privacy
========================================================= */
.clm {
  margin: 0 0 25px 0; /*--20230909--*/
}
/*--20230909--*/
/* =========================================================
 page-privacy
========================================================= */
#page-privacy #praivacyArea dl {
  margin: 20px 0 0 0;
}

#page-privacy #praivacyArea ul {
  list-style-type: disc;
  margin: 20px 0;
  padding: 0 0 0 30px;
}

#page-privacy #praivacyArea ul.list {
  list-style-type: none;
  padding: 0;
}

#page-privacy #praivacyArea ol {
  list-style-type: decimal;
  padding: 0 0 0 30px;
  margin: 20px 0;
}

/* =========================================================
  naviArea
========================================================= */
#naviArea .docs ul,
#naviArea .categoryType ul {
  display: flex;
  flex-wrap: wrap;
  /*--    justify-content: space-between;  --*/
  padding: 0;
  margin: 0;
  list-style: none;
  @media (max-width: 599px) {
  }
}

#naviArea .docs ul li,
#naviArea .categoryType ul li {
  flex-basis: calc(32.9% - 9px);
  margin: 0 4px 10px 4px;
  font-size: 86%;
  text-align: center;
  @media (max-width: 599px) {
    flex-basis: calc(100% - 9px);
  }
}

#naviArea > .contentGpArticleDocs > .docs > ul > li > .title_link {
  display: block;
}

#naviArea .docs ul li .publish_date,
#naviArea .categoryType ul li .publish_date {
  display: none;
}

#naviArea ul#voice li figure {
  line-height: 0;
}

#naviArea ul#voice li img {
  width: 100%;
  height: auto;
}

#naviArea ul#voice li a {
  display: block;
  text-decoration: none;
  line-height: 1.4;
}
#naviArea ul#voice li a:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.pagination {
  text-align: center;
}

.searchInfo {
  /*--20230905--*/
  margin-bottom: 40px;
}

#naviArea figure {
  /*--20230905--*/
  text-align: center;
  margin: inherit;
  margin-bottom: 20px;
}

#naviArea figure img {
  /*--20230905--*/
  width: 80%;
  height: auto;
}
.post_content #naviArea section ul li {
  /*--20230905--*/
  padding: 10px 0;
}
.back {
  text-align: center;
}

/* =========================================================
  sitemap
========================================================= */
ul.sitemap li {
  list-style: none;
}

.post_content ul.sitemap {
  margin: 0;
  padding: 0;
}
.post_content ul.sitemap li {
  padding: 0;
}

ul.level1 {
  border-bottom: 1px dotted var(--mono-color-3);
}

ul.sitemap li.level1 {
  padding: 8px 0;
  border-top: 1px dotted var(--mono-color-1);
}
ul.sitemap li.level1 a {
  text-decoration: none;
}

ul.sitemap li.level1 a.level1 {
  /*--20230909--*/
  padding: 10px;
  display: block;
  background-color: var(--color_htag);
  color: #fff;
  padding: 10px;
  margin: 0 0 20px 0;
}

ul.sitemap ul.level2 {
  /*--20230909--*/
  padding: 0 0 0 0;
}

ul.sitemap ul.level2 ul {
  /*--20230909--*/
  padding: 20px 0 10px 0px;
}

ul.sitemap li.level2 {
  padding: 8px 0;
  border-bottom: 1px dotted var(--mono-color-3);
}

ul.sitemap li.level2 a.level2 {
  /*--20230909--*/
  display: block;
  margin: 5px 0 3px 0px;
  padding: 0 0 10px 0;
  border-bottom: 1px dotted #000;
  font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
}

ul.level3 {
  display: flex;
  flex-wrap: wrap;
}
ul.level3 > li {
  /*--20230909--*/
  flex-basis: 30%;
}

ul.sitemap li.sitemap {
  width: 100%;
}
